




import { defineComponent } from "@vue/composition-api";
import TheBackground from "@/components/singletons/TheBackground.vue";

export default defineComponent({
  components: {
    TheBackground
  },

  props: {},

  setup() {}
});
