import { LogLevelEnum } from "./LogLevelEnum";
import { ILogMessageBuilder } from "./ILogMessageBuilder";

export class LogMessageBuilder implements ILogMessageBuilder {
  public build(message: any, date: Date, level: LogLevelEnum, caller?: string): any {
    let msg = date.toISOString() + " [" + level + "]";
    msg += caller ? " [" + caller + "]" : "";
    msg += ": " + (typeof message === "string" ? message : "");
    return msg;
  }
}
