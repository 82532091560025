import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { Type } from "class-transformer";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";
import { Delegate } from "@/ts/system/Delegate";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { IncorrectAnswerActionEnum } from "@/ts/domain/Enums";
import { BaseLesson } from "@/ts/domain/BaseLesson";

export type SelectorLessonExerciseActionDelegate = (exercise: SelectorExercise, played: SelectorExerciseOption, selected: SelectorExerciseOption) => Promise<any>;

export class SelectorLesson extends BaseLesson {
  // Server props
  @Type(() => SelectorExercise)
  override exercises: SelectorExercise[] = [];

  incorrectAnswerAction: IncorrectAnswerActionEnum = IncorrectAnswerActionEnum.Continue;

  // Frontend props
  override get currentExercise(): SelectorExercise {
    return super.currentExercise as SelectorExercise;
  }

  incorrectAnswers: SelectorExerciseOption[] = [];

  protected workflow: any = {
    onCorrectItemSelected: () => {},
    onIncorrectItemSelected: () => {},
    onExerciseFinished: () => {}
  }

  // Delegates
  onCorrectItemSelected: Delegate<SelectorLessonExerciseActionDelegate> = new Delegate<SelectorLessonExerciseActionDelegate>();
  onIncorrectItemSelected: Delegate<SelectorLessonExerciseActionDelegate> = new Delegate<SelectorLessonExerciseActionDelegate>();

  // Constructors
  constructor() {
    super();

    this.workflow.onCorrectItemSelected = async (exercise: SelectorExercise, played: SelectorExerciseOption, selected: SelectorExerciseOption) => {
      console.warn(this.constructor.name + " onCorrectItemSelected.");
      await this.onCorrectItemSelected.execute(exercise, played, selected);

      await this.currentExercise.continue();
    };

    this.workflow.onIncorrectItemSelected = async (exercise: SelectorExercise, played: SelectorExerciseOption, selected: SelectorExerciseOption) => {
      console.warn(this.constructor.name + " onIncorrectItemSelected.");
      this.incorrectAnswers.push(played);
      await this.onIncorrectItemSelected.execute(exercise, played, selected);

      switch (this.incorrectAnswerAction) {
        case IncorrectAnswerActionEnum.Continue:
        default:
          await this.currentExercise.continue();
          break;
        case IncorrectAnswerActionEnum.Repeat:
          await this.currentExercise.repeat(this.lessonFilter);
          break;
        case IncorrectAnswerActionEnum.RepeatItem:
          await this.currentExercise.startAtIndex(this.currentExercise.currentIndex);
          break;
      }
    };

    this.workflow.onExerciseFinished = async () => {
      console.warn(this.constructor.name + " onExerciseFinished.");
      await this.nextExercise();
    };
  }

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<any> {
    this.lessonFilter = filter;
    this.filter(filter);
    await this.executeStart();
    return await this.startAtIndex(0, filter);
  }

  async nextExercise(): Promise<any> {
    return await this.startAtIndex(++this.currentIndex, this.lessonFilter);
  }

  protected async startAtIndex(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    const result = await super.startAtIndex(index, filter);
    if (result) {
      return true;
    }

    await this.executeFinish();
    return false;
  }

  // Methods
  protected override bindExerciseEvents(exercise: SelectorExercise) {
    exercise.onCorrectItemSelected.add(this.workflow.onCorrectItemSelected);
    exercise.onIncorrectItemSelected.add(this.workflow.onIncorrectItemSelected);
    exercise.onFinished.add(this.workflow.onExerciseFinished);

    super.bindExerciseEvents(exercise);
  }
}