// TODO: change array to set (?)
export class Delegate<T extends Function> {
  private _items: DelegateStruct<T>[] = [];
  public get items(): DelegateStruct<T>[] {
    return this._items;
  }

  add(items: T | T[], runOnce: boolean = false, index: number = -1): void {
    if (!Array.isArray(items)) {
      items = [items];
    }

    for (let i = 0; i < items.length; i++) {
      this._add(items[i], runOnce, index);
    }
  }

  remove(items: T | T[]): void {
    if (!Array.isArray(items)) {
      items = [items];
    }

    for (let i = 0; i < items.length; i++) {
      this._remove(items[i]);
    }
  }

  merge(delegate: Delegate<T>, runOnce: boolean = false, position: number = -1) {
    for (let i = 0; i < delegate.items.length; i++) {
      // Run only once if either argument or merged delegate flag is true
      const item = delegate.items[i];
      this._add(item.func, runOnce || item.runOnce, position);
    }
  }

  indexOf(item: T): number {
    return this.items.map(e => e.func).indexOf(item);
  }

  async execute(...args: any) {
    const itemsToRemove = [];

    for (let i = 0; i < this._items.length; i++) {
      const once = this._items[i].runOnce;
      const func = this._items[i].func;

      if (func) {
        await func(...args);
      }

      if (once) {
        itemsToRemove.push(func);
      }
    }

    this.remove(itemsToRemove);
  }

  private _add(item: T, runOnce: boolean, index: number = -1): void {
    if (this.indexOf(item) === -1) {
      const struct = new DelegateStruct<T>(item, runOnce);
      if (index === -1) {
        this._items.push(struct);
      } else {
        this._items.splice(index, 0, struct);
      }
    }
  }

  private _remove(item: T): void {
    const index = this.indexOf(item);
    if (index !== -1) {
      this._items.splice(index, 1);
    }
  }
}

export class DelegateStruct<T extends Function> {
  constructor(public readonly func: T, public readonly runOnce: boolean) {}
}
