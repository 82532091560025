var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":_vm.animationClass}},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],staticClass:"syllable-wrap px-3 px-sm-8 px-lg-10",class:[
      _vm.exercise.highlight ? _vm.exercise.highlight : '',
      {
        disabled: _vm.isDisabled,
        clickable: _vm.isClickable,
        playing: _vm.exercise.isPlaying,
        played: _vm.exercise.isFinished,
        inverted: _vm.isInverted
      }
    ],attrs:{"disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm._onClick.apply(null, arguments)}}},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.exercise.text)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }