import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { Delegate } from "@/ts/system/Delegate";

export type CompositeExerciseDelegate<T extends BaseExercise> = (exercise: T, index: number) => Promise<any>;

// Provides base functionality for iterable exercises such as selector exercise
export abstract class BaseCompositeExercise<T extends BaseExercise>
  extends BaseExercise {
  // Server props
  // https://github.com/typestack/class-transformer/blob/master/sample/sample4-generics/SuperCollection.ts
  // Generic decorators are not supported, see _readme.md
  readonly children: T[] = [];

  // Frontend props
  currentIndex: number = 0;
  active: T[] = [];

  get currentItem(): T {
    return this.active[this.currentIndex];
  }

  // Delegates
  // Event raised alongside with child exercise onBeforeStarted
  onItemStarted: Delegate<CompositeExerciseDelegate<T>> = new Delegate<CompositeExerciseDelegate<T>>();
  // Event raised alongside with child exercise onFinished
  onItemFinished: Delegate<CompositeExerciseDelegate<T>> = new Delegate<CompositeExerciseDelegate<T>>();

  // Methods
  abstract start(filter?: LessonExerciseFilter): Promise<boolean>;
  abstract continue(): Promise<boolean>;
  abstract repeat(filter?: LessonExerciseFilter): Promise<boolean>;
  abstract stop(): Promise<void>;

  override async terminateExercise() {
    // this._terminateExercise = true;
    await super.terminateExercise();
    await this.active.forEach(e=>e.terminateExercise());

    this.reset();
  }

  override reset(): void {
    super.reset();

    this.currentIndex = 0;
    this.children.forEach(e => e.reset());
  }

  filter(filter?: LessonExerciseFilter): void {
    this.active = !filter || !filter.keys || !filter.keys.length ?
      this.children :
      this.children.filter(e => filter.keys.some(key => key === e.text));
  }

  // Starts child exercise at a given index <br />
  // If no new child exercise found, executes finish <br />
  // Returns true if exercise is found and false otherwise
  async startAtIndex(index: number): Promise<boolean> {
    this.currentIndex = index;
    const exercise = this.currentItem;

    if (exercise) {
      this.bindExerciseEvents(exercise, index);
      await exercise.start();
      return true;
    }

    // No next child exercise found
    return false;
  }

  // Starts exercise at given index and continues playing other exercises after the first one is finished
  protected async startFromIndexRecursively(index: number): Promise<boolean> {
    this.currentIndex = index;
    const result = await this.startAtIndex(this.currentIndex);

    if (result) {
      return await this.startFromIndexRecursively(++this.currentIndex);
    }

    return false;
  }

  protected bindExerciseEvents(exercise: BaseExercise, index: number) {
    exercise.onBeforeStarted.add(async () => {
      console.warn(this.constructor.name + " (" + this.text + ") onItemStarted (" + exercise.text + ").");
      await this.onItemStarted.execute(exercise, index);
    }, true);

    exercise.onFinished.add(async () => {
      console.warn(this.constructor.name + " (" + this.text + ") onItemFinished (" + exercise.text + ").");
      await this.onItemFinished.execute(exercise, index);
    }, true);
  }
}