import { SysHelper } from "@/ts/system/SysHelper";
import { LoginImageWithKey } from "@/ts/domain/LoginImageWithKey";

export function useLoginHelper() {
  function generateImages(correctKey: number, numOfImages: number = 3): LoginImageWithKey[] {
    const result: LoginImageWithKey[] = [];

    result.push(
      new LoginImageWithKey(correctKey, require("@/assets/student-keys/" + getPngName(correctKey)))
    );

    while (result.length < numOfImages) {
      const key = SysHelper.randomBetween(1, 31);
      const png = getPngName(key);
      if (!result.some(e => e.key === key)) {
        result.push(new LoginImageWithKey(key, require("@/assets/student-keys/" + png)));
      }
    }

    return SysHelper.shuffleArray(result);
  }

  function getPngName(key: number) {
    return "sk" + key + ".png";
  }

  return {
    generateImages
  };
}
