import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";

/*
  Simply plays associated syllable and finishes itself
 */
export class SyllableExercise extends BaseExercise {
  // Frontend props
  // Interchangeable color
  highlight: string = "";

  async start(): Promise<void> {
    await this.executeStart();
    await this.executeFinish();
  }

  async repeat(): Promise<void> {
    this.reset();
    return await this.start();
  }

  override reset() {
    this.highlight = "";

    super.reset();
  }

  stop(): Promise<void> {
    throw "Not implemented yet.";
  }
}
