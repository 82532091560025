import Vue from "vue";
import VueRouter, { NavigationGuardNext, RouteConfig, Route } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Test from "@/views/Test.vue";
import { useUserStore } from "@/store/useUserStore";
import { RouteMetadata } from "@/ts/RouteMetadata";
import { Config } from "@/ts/system/Config";

Vue.use(VueRouter);

function requireAuthentication(to: Route, from: Route, next: NavigationGuardNext) {
  const userStore = useUserStore();
  if (userStore.isAuthenticated.value) {
    if (to.name === "login") {
      next("/");
      return;
    }

    next();
    return;
  }

  next("/login");
}

function requireNoAuthentication(to: Route, from: Route, next: NavigationGuardNext) {
  const userStore = useUserStore();
  if (!userStore.isAuthenticated.value) {
    next();
    return;
  }

  next("/");
}

const routes: Array<RouteConfig> = [
  {
    path: "/test",
    name: "test",
    component: Test,
    meta: { background: "the-blending-background" } as RouteMetadata,
    beforeEnter: requireAuthentication
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { showLeftPanel: true } as RouteMetadata,
    beforeEnter: requireNoAuthentication
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { showFooter: false, showLeftPanel: true } as RouteMetadata,
    beforeEnter: requireAuthentication
  },
  {
    path: "/sonidos",
    name: "sonidos",
    meta: { externalUrl: "http://estudiante.estrellita.com/" } as RouteMetadata
  },
  {
    path: "/combined-modules",
    name: "combined-modules",
    beforeEnter: requireAuthentication,
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blending" */ "@/views/combined-modules/Index.vue")
  },
  {
    path: "/blending",
    name: "blending",
    beforeEnter: requireAuthentication,
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blending" */ "@/views/blending/Index.vue")
  },
  {
    path: "/blending/:module/:segment/:lesson",
    name: "blending-lesson",
    beforeEnter: requireAuthentication,
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blending" */ "@/views/blending/Lesson.vue")
  },
  {
    path: "/palabras",
    name: "palabras",
    beforeEnter: requireAuthentication,
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blending" */ "@/views/palabras/Index.vue")
  },
  {
    path: "/lunita",
    name: "lunita",
    beforeEnter: requireAuthentication,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lunita" */ "@/views/Lunita.vue")
  },
  {
    path: "/sandbox",
    name: "sandbox",
    beforeEnter: requireAuthentication,
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sandbox" */ "@/views/sandbox/Index.vue")
  },
  {
    path: "*",
    name: "page-not-found",
    meta: { background: "the-blending-background" } as RouteMetadata,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blending" */ "@/views/PageNotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: Config.get().baseUrl,
  routes
});

/*
  Code below let us use URLs to external websites
  Usage:
  1. Add route to the router above and set meta.externalUrl to desired URL
  For example,
  {
    path: "/ext",
    name: "ext",
    meta: { externalUrl: "https://somesite.com/" }
  },
  2. Use vue-router as usual
 */
// Fix matcher (as a result, user will see external URL on the website, instead of local VUE route)
// Matcher is not a part of public API, ts-ignore needed
// @ts-ignore
const match = router.matcher.match;
// @ts-ignore
router.matcher.match = (...args) => {
  const route = match.apply(router, args);
  if (route.meta.externalUrl) {
    return Object.freeze({ ...route, fullPath: route.meta.externalUrl });
  }
  return route;
};

// Handle external routes (actual redirect
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  // @ts-ignore
  const target = to.meta.target || "_blank";
  // @ts-ignore
  if (to.meta.externalUrl) {
    // @ts-ignore
    window.open(to.meta.externalUrl, target);
    return next(false);
  }
  next();
});

export default router;
