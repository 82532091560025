import { RuntimeConfiguration } from "@/ts/RuntimeConfiguration";
import runtime from "@/runtime.config.json";

export class Config {
  private static envPrefix: string = "VUE_APP_ESTR_";

  public static get(): RuntimeConfiguration {
    const typedRuntime = runtime as RuntimeConfiguration;
    const result = new RuntimeConfiguration();

    result.baseUrl = process.env.BASE_URL;
    result.authApiUrl = this.parse<string>(typedRuntime.authApiUrl, this.getEnvVar("AUTH_API_URL"));
    result.lessonsApiUrl = this.parse<string>(
      typedRuntime.lessonsApiUrl,
      this.getEnvVar("LESSONS_API_URL")
    );

    return result;
  }

  private static getEnvVar(key: string): string {
    return process.env[this.envPrefix + key];
  }

  private static parse<T extends string | boolean | number>(value: T, fallback: string): any {
    if (typeof value !== "undefined") {
      return value;
    }

    switch (typeof value) {
      case "boolean":
        return Boolean(fallback);
      case "number":
        return Number(fallback);
      default:
        return String(fallback);
    }
  }
}
