











import { defineComponent } from "@vue/composition-api";
import TheLeftPanelLayout from "@/components/singletons/TheLeftPanelLayout.vue";

export default defineComponent({
  components: {
    TheLeftPanelLayout
  },

  props: {
    showLeftPanel: Boolean
  },

  setup() {}
});
