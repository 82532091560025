

























import { defineComponent, ref, PropType } from "@vue/composition-api";
import LoginScreenWrap from "@/components/login/LoginScreenWrap.vue";
import { LoginImageWithKey } from "@/ts/domain/LoginImageWithKey";

export default defineComponent({
  components: {
    LoginScreenWrap
  },

  props: {
    images: {
      type: Array as PropType<LoginImageWithKey[]>,
      required: true
    }
  },

  setup(props, { emit }) {
    const header = ref("Por favor, regístrese");
    const subheader = ref("Haga clic en la imagen de su clave.");

    function imageClick(image: LoginImageWithKey): void {
      emit("login", image.key);
    }

    function cancel(): void {
      emit("cancel");
    }

    return {
      header,
      subheader,

      imageClick,
      cancel
    };
  }
});
