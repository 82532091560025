// Required by class-transformer @Type decorators
import "reflect-metadata";

import Vue from "vue";
import { createApp } from "@vue/composition-api";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

// Icons
import "@mdi/font/css/materialdesignicons.css";

// Global CSS
import "@/assets/scss/global.scss";
import "@/assets/scss/app.scss";
import "@/assets/scss/transitions.scss";

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);

createApp({
  router,
  vuetify,
  render: (h: any) => h(App)
}).mount("#app");
