<template>
  <v-container fill-height fluid class="d-flex align-start ma-0 pa-0">
    <v-row class="fill-height ma-0 pa-0" style="z-index: 99;">
      <v-col
        v-if="showLeftPanel"
        class="left-panel col-xl-4 col-lg-3 col-md-2 hidden-sm-and-down ma-0 pa-0"
      ></v-col>
      <v-col :class="[mainPanelClass]" class="main-panel ma-0 pa-0 d-flex flex-column">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: {},

  props: {
    showLeftPanel: Boolean
  },

  setup(props) {
    const mainPanelClass = computed(() => {
      return props.showLeftPanel ? "col-xl-8 col-lg-9 col-md-10" : "col-xs-12";
    });

    return {
      mainPanelClass
    };
  }
});
</script>

<style scoped lang="scss">
.left-panel {
  background: url(~@/assets/left-panel-background.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 1;
}
</style>
