import { Type } from "class-transformer";

import { ExerciseAnswersShowEnum } from "@/ts/domain/Enums";

import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import { SelectorSequenceExercise } from "@/ts/domain/exercises/SelectorSequenceExercise";
import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";
import { BaseLesson } from "@/ts/domain/BaseLesson";

export class SelectorSequenceLesson extends BaseLesson {
  // Server props
  @Type(() => SelectorSequenceExercise)
  override exercises: SelectorSequenceExercise[] = [];

  public exerciseAnswers: string[] = [];

  showExerciseAnswers: ExerciseAnswersShowEnum = ExerciseAnswersShowEnum.ShowOnLessonFinish;

  // Frontend props
  override get currentExercise(): SelectorSequenceExercise {
    return super.currentExercise as SelectorSequenceExercise;
  }

  incorrectAnswers: Set<SelectorExerciseOption> = new Set<SelectorExerciseOption>();

  protected workflow: any = {
    onBeforeSequenceStarted: () => {},
    onBeforeSequenceFinished: () => {},
    onSequenceFinished: () => {},
    onSequenceFailed: () => {},

    onSelectorCorrectItemSelected: () => {},
    onSelectorIncorrectItemSelected: () => {},
  }

  // Constructors
  constructor() {
    super();

    this.workflow.onBeforeSequenceStarted = async (sequence: SelectorSequenceExercise) => {
      if (this.showExerciseAnswers === ExerciseAnswersShowEnum.ShowOnExerciseFinish) {
          this.exerciseAnswers.push(
            sequence.placeholder
          );
      }

      // subscribing to each selector exercise events
      sequence.onItemStarted.add(async (selector: SelectorExercise) => {
        this.bindSelectorEvents(selector);
      });
    };

    this.workflow.onBeforeSequenceFinished = async () => {
      if (this.showExerciseAnswers === ExerciseAnswersShowEnum.ShowOnLessonFinish) {
        this.setExerciseAnswers();
      }
    };

    this.workflow.onSequenceFinished = async () => {
      console.warn(this.constructor.name + " onSequenceFinished.");

      // Continue to next exercise;
      await this.nextExercise();
    };

    this.workflow.onSequenceFailed = async (sequence: SelectorSequenceExercise, wrongAnswers: Set<SelectorExerciseOption>) => {
      wrongAnswers.forEach(e => {
        this.incorrectAnswers.add(e);
      });
    }

    this.workflow.onSelectorCorrectItemSelected = async () => {
      if (this.showExerciseAnswers === ExerciseAnswersShowEnum.ShowOnExerciseFinish) {
        this.setExerciseAnswers();
      }

      // exercises themselves control their flow
      await this.currentExercise.continue();
    };

    this.workflow.onSelectorIncorrectItemSelected = async () => {
      // exercises themselves control their flow
      //? We need to stop lesson when handleFailExerciseWithConType return some con(check condisions for that method), it is better to set it from SelectorExercise or from SelectorSequenceLesson
      const conTypeToRepeat = this.currentExercise.currentItem.handleFailExerciseWithConType(this.currentExercise.currentItem.active[this.currentExercise.currentItem.currentIndex].text);
      if (conTypeToRepeat) {
        // Raising onFailed and pass con type to onFailed delegate(to use it in filter)
        this.isFailed = true;
        console.warn(this.constructor.name + " onFailed.");
        await this.onFailed.execute(this, this.incorrectAnswers, conTypeToRepeat);

        await this.executeFinish();
        return false;
      }

      await this.currentExercise.continue();
    };
  }

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<any> {
    this.lessonFilter = filter;
    this.filter(filter);
    await this.executeStart();
    return await this.startAtIndex(0, filter);
  }

  //! adding new reset - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
  override reset() {
    this.incorrectAnswers.clear();

    super.reset();
  }

  override async nextExercise(): Promise<any> {
    return await this.startAtIndex(++this.currentIndex, this.lessonFilter);
  }

  protected override async startAtIndex(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    const result = await super.startAtIndex(index, filter);
    if (result) {
      return true;
    }

    if (this.incorrectAnswers.size !== 0) {
      // Raising onFailed
      this.isFailed = true;
      console.warn(this.constructor.name + " onFailed.");
      await this.onFailed.execute(this, this.incorrectAnswers);
    }

    await this.executeFinish();
    return false;
  }

  setExerciseAnswers(): void {
    this.exerciseAnswers.splice(
      this.currentIndex,
      1,
      this.currentExercise.text
    );
  }

  clearExerciseAnswers(): void {
    this.exerciseAnswers = [];
  }

  protected override bindExerciseEvents(sequence: SelectorSequenceExercise) {
    super.bindExerciseEvents(sequence);

    sequence.onBeforeStarted.add(this.workflow.onBeforeSequenceStarted);
    sequence.onBeforeFinished.add(this.workflow.onBeforeSequenceFinished);
    sequence.onFinished.add(this.workflow.onSequenceFinished);
    sequence.onFailed.add(this.workflow.onSequenceFailed);
  }

  protected bindSelectorEvents(selector: SelectorExercise) {
    selector.onCorrectItemSelected.add(this.workflow.onSelectorCorrectItemSelected);
    selector.onIncorrectItemSelected.add(this.workflow.onSelectorIncorrectItemSelected);
  }
}