import { ref, computed, Ref, ComputedRef } from "@vue/composition-api";

import CONSTANTS from "@/constants/index";

import { useApi } from "@/ts/composition/useApi";
import { Config } from "@/ts/system/Config";
import { useJwtTokenProvider } from "@/ts/composition/useJwtTokenProvider";
import { IStore } from "@/ts/vue/IStore";
import { JwtTokenContent } from "@/ts/JwtTokenContent";

const AUTH_SERVICE_URL = Config.get().authApiUrl;

let data: Ref;
let errors: Ref;
let loginKeyReactivity: Ref;

export function useUserStore(): IUserStore {
  data = data || ref();
  errors = errors || ref();
  loginKeyReactivity = loginKeyReactivity || ref(0);

  const tokenProvider = useJwtTokenProvider();

  const api = useApi(AUTH_SERVICE_URL);
  const isAuthenticatedInternal = computed(() => {
    const c = loginKeyReactivity.value; // this line needed for localStorage reactivity
    return (
      tokenProvider.isAuthenticated.value &&
      !!localStorage.getItem(CONSTANTS.CORRECT_KEY_SELECTED_KEY)
    );
  });

  const userInfo = tokenProvider.tokenContent;

  // Username abstracts both username itself OR email
  function login(nickname: any, classLogin: any): Promise<any> {
    return api
      .post(CONSTANTS.AUTH_URL, {
        nickname: nickname,
        classLogin: classLogin
      })
      .then(response => {
        if (response.data.accessToken) {
          tokenProvider.setToken(response.data.accessToken);
        }

        data.value = response.data;
        return response;
      })
      .catch(e => {
        errors.value = e.response;
        throw e;
      });
  }

  function validateKey(key: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const content = tokenProvider.tokenContent;
      if (Number(content.value?.LoginKey) === key) {
        localStorage.setItem(CONSTANTS.CORRECT_KEY_SELECTED_KEY, "1");
        resolve();
      } else {
        localStorage.setItem(CONSTANTS.CORRECT_KEY_SELECTED_KEY, "");
        reject();
      }
      loginKeyReactivity.value++;
    });
  }

  function logout(): Promise<any> {
    tokenProvider.setToken("");
    localStorage.setItem(CONSTANTS.CORRECT_KEY_SELECTED_KEY, "");

    return Promise.resolve();
  }

  return {
    data,
    errors,

    userInfo,

    isLoading: api.isLoading,
    activeRequestsCount: api.activeRequestsCount,
    isAuthenticated: isAuthenticatedInternal,

    login,
    validateKey,
    logout
  } as IUserStore;
}

export interface IUserStore extends IStore<string> {
  isAuthenticated: ComputedRef<boolean>;
  userInfo: ComputedRef<JwtTokenContent>;

  login(nickname: any, classLogin: any): Promise<any>;
  validateKey(key: number): Promise<void>;
  logout(): Promise<void>;
}
