import { Type } from "class-transformer";

import { LessonInstructions } from "@/ts/domain/lessons/LessonInstructions";
import { LessonFinishedData } from "@/ts/domain/lessons/LessonFinishedData";
import { BaseExercise, ExerciseDelegate } from "@/ts/domain/exercises/BaseExercise";
import { GridExercise } from "@/ts/domain/exercises/GridExercise";
import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { SelectorSequenceExercise } from "@/ts/domain/exercises/SelectorSequenceExercise";
import { ConcatenatorSequenceExercise } from "@/ts/domain/exercises/ConcatenatorSequenceExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import { SoundPlayer } from "@/ts/sounds/SoundPlayer";

import { Delegate } from "@/ts/system/Delegate";

export type LessonDelegate = (lesson: BaseLesson) => Promise<any>;
export type LessonFailedDelegate = (
  lesson: BaseLesson,
  wrongAnswers: Set<BaseExercise>,
  conTypeToRepeat?: string
) => Promise<any>;

export abstract class BaseLesson {
  // Server props
  id: string = "";
  name: string = "";
  sequenceNumber: number = 0;
  description: string = "";
  type: string = "";
  useExerciseFilter: boolean = false;
  clearExerciseFilter: boolean = false;

  // TODO
  isCompleted: boolean = false;

  @Type(() => LessonInstructions)
  instructions: LessonInstructions = new LessonInstructions();

  @Type(() => BaseExercise, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: GridExercise, name: "grid-exercise" },
        { value: SelectorExercise, name: "selector-exercise" },
        { value: SelectorSequenceExercise, name: "selector-sequence-exercise" },
        {
          value: ConcatenatorSequenceExercise,
          name: "concatenator-sequence-exercise"
        }
      ]
    }
  })
  exercises: (
    | GridExercise
    | SelectorExercise
    | SelectorSequenceExercise
    | ConcatenatorSequenceExercise
  )[] = [];

  @Type(() => LessonFinishedData)
  lessonFailure: LessonFinishedData | undefined;

  @Type(() => LessonFinishedData)
  lessonSuccess: LessonFinishedData | undefined;

  // Special server prop, helps LessonsFactory emulate server responses
  serverMimic: any = {};

  // Frontend props
  currentIndex: number = 0;
  isFinished: boolean = false;
  isStarted: boolean = false;
  // Only lessons with user interaction may fail
  isFailed: boolean = false;

  // Active exercises depending on current lesson state
  active: (
    | GridExercise
    | SelectorExercise
    | SelectorSequenceExercise
    | ConcatenatorSequenceExercise
  )[] = [];

  public get currentExercise(): BaseExercise {
    return this.active[this.currentIndex];
  }

  private _instructionsPlayer?: SoundPlayer;
  protected lessonFilter?: LessonExerciseFilter;

  // Delegates
  onBeforeStarted: Delegate<LessonDelegate> = new Delegate<LessonDelegate>();
  onStarted: Delegate<LessonDelegate> = new Delegate<LessonDelegate>();
  onBeforeFinished: Delegate<LessonDelegate> = new Delegate<LessonDelegate>();
  onFinished: Delegate<LessonDelegate> = new Delegate<LessonDelegate>();
  onFailed: Delegate<LessonFailedDelegate> = new Delegate<LessonFailedDelegate>();

  onBeforeExerciseStarted: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  onExerciseStarted: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  onBeforeExerciseFinished: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  onExerciseFinished: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();

  // Methods
  abstract start(filter?: LessonExerciseFilter): Promise<any>;
  abstract nextExercise(): Promise<any>;

  reset() {
    this.currentIndex = 0;
    this.isFinished = false;
    this.isFailed = false;
    this.isStarted = false;
    this.exercises.forEach(e => e.reset());
  }

  filter(filter?: LessonExerciseFilter) {
    this.active =
      this.useExerciseFilter && filter && filter.keys && filter.keys.length
        ? this.exercises.filter(e => filter.keys.some(key => key === e.text))
        : this.exercises;
  }

  async playInstructions(): Promise<any> {
    if (!this._instructionsPlayer) {
      this._instructionsPlayer = new SoundPlayer(this.instructions.audio);
    }

    return await this._instructionsPlayer.playRecursively();
  }

  protected async startRecursively(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    this.currentIndex = index;

    const result = await this.startAtIndex(this.currentIndex, filter);

    if (result) {
      return await this.startRecursively(++this.currentIndex, filter);
    }

    return false;
  }

  protected async startAtIndex(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    const exercise = this.active[index];

    if (exercise) {
      this.bindExerciseEvents(exercise);
      await exercise.start(filter);
      return true;
    }

    // no next exercise found
    return false;
  }

  protected bindExerciseEvents(exercise: BaseExercise) {
    exercise.onBeforeStarted.merge(this.onBeforeExerciseStarted, false, 0);
    exercise.onStarted.merge(this.onExerciseStarted, false, 0);
    exercise.onBeforeFinished.merge(this.onBeforeExerciseFinished, false, 0);
    exercise.onFinished.merge(this.onExerciseFinished, false, 0);
  }

  // Executes actions in order to start exercise properly
  protected async executeStart() {
    this.reset();

    this.isStarted = true;

    console.warn(this.constructor.name + " onBeforeStarted.");
    await this.onBeforeStarted.execute(this);

    await this.playInstructions();

    console.warn(this.constructor.name + " onStarted.");
    await this.onStarted.execute(this);
  }

  protected async executeFinish() {
    console.warn(this.constructor.name + " onBeforeFinished.");
    await this.onBeforeFinished.execute(this);

    this.isFinished = true;

    console.warn(this.constructor.name + " onFinished.");
    await this.onFinished.execute(this);
  }

  // the exercise can be stopped before the user completes it(for example when user goes back to home page)
  // TODO: clear delegates
  async executeTermination(): Promise<void> {
    await this._instructionsPlayer?.terminatePlayer();

    await this.reset();
    await this.exercises.forEach(async e => await e.terminateExercise());
  }
}
