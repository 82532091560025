import { computed, ref, Ref } from "@vue/composition-api";
import { JwtTokenProvider } from "@/ts/JwtTokenProvider";

let reactivity: Ref;

export function useJwtTokenProvider() {
  reactivity = reactivity || ref(0);

  const isAuthenticated = computed(() => {
    const c = reactivity.value;
    return !!JwtTokenProvider.getToken();
  });

  const tokenContent = computed(() => {
    const c = reactivity.value;
    return JwtTokenProvider.getTokenContent();
  });

  function setToken(token: string) {
    reactivity.value++;
    JwtTokenProvider.setToken(token);
  }

  function getToken() {
    return JwtTokenProvider.getToken();
  }

  return {
    tokenContent,
    isAuthenticated,

    getToken,
    setToken
  };
}
