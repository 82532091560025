import { Type } from "class-transformer";

import CONSTANTS from "@/constants";

import { SoundPlayer } from "@/ts/sounds/SoundPlayer";
import { Sound } from "@/ts/sounds/Sound";

import { RepetitionModeEnum, ShuffleModeEnum } from "@/ts/domain/Enums";

import { Delegate } from "@/ts/system/Delegate";
import { SysHelper } from "@/ts/system/SysHelper";

import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import { ExerciseDelegate } from "@/ts/domain/exercises/BaseExercise";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";
import { BaseCompositeExercise } from "@/ts/domain/exercises/BaseCompositeExercise";

export type SelectorExerciseDelegate = (exercise: SelectorExercise, played: SelectorExerciseOption, selected: SelectorExerciseOption) => Promise<any>;
export type SelectorExerciseFailedDelegate = (exercise: SelectorExercise, wrongAnswers: Set<SelectorExerciseOption>, failedConType?: string) => Promise<any>;

export interface IConTypeIncorrectAnswers {
  a: number;
  e: number;
  i: number;
  o: number;
  u: number;
}

/*
  Plays one of <b>correct</b> options and awaits for user input
  Class consumer should call checkAnswer method in order to continue execution
 */
export class SelectorExercise extends BaseCompositeExercise<SelectorExerciseOption> {
  // Server props
  @Type(() => SelectorExerciseOption)
  readonly children: SelectorExerciseOption[] = [];

  // TODO: replace incentive Sound from exercises data to separate assets foder
  @Type(() => Sound)
  readonly incentiveSounds: Sound[] = [];

  limit: number | undefined = undefined;

  // Check if we need to filter children of current exercise
  isFilterChildren: boolean = false;

  // If true, we group incorrect answers by con type(syllable con "a", con "e", con "i")
  isGroupIncorrectAnswersByConType: boolean = false;

  conTypeIncorrectAnswersPerAttempt: IConTypeIncorrectAnswers = {
    a: 0,
    e: 0,
    i: 0,
    o: 0,
    u: 0
  }

  conTypeIncorrectAnswersAllTime: IConTypeIncorrectAnswers = {
    a: 0,
    e: 0,
    i: 0,
    o: 0,
    u: 0
  };

  // Default behavior: no shuffle
  shuffle: ShuffleModeEnum = ShuffleModeEnum.NoShuffle;
  // Default behavior: no repeat
  repetition: RepetitionModeEnum = RepetitionModeEnum.NoRepeat;

  // Frontend props
  correctAnswers: Set<SelectorExerciseOption> = new Set<SelectorExerciseOption>();
  incorrectAnswers: Set<SelectorExerciseOption> = new Set<SelectorExerciseOption>();
  incorrectAnswersAllTime: Set<SelectorExerciseOption> = new Set<SelectorExerciseOption>();

  maxNumberOfAttempts: number = CONSTANTS.MAX_INCORRECT_ANSWERS_ATTEMPTS;
  currentAttemptNumber: number = CONSTANTS.DEFAULT_ATTEMPT_NUMBER;
  // TODO: create groupAttempt to handle incorrect asnwers per group later;
  // groupAttempt

  get correctText(): string {
    return [...this.correctAnswers].map(e => e.text).join('');
  }

  private _active: SelectorExerciseOption[] = [];
  private _incentiveSoundsPlayer?: SoundPlayer;
  protected _isLastCorrect: boolean = true;

  // Delegates
  // Event raised if validated answer matches last played option <br />
  // Consumer code must call checkAnswer in order to validate answer
  onCorrectItemSelected: Delegate<SelectorExerciseDelegate> = new Delegate<SelectorExerciseDelegate>();
  // Event raised if validated answer not matches last played option <br />
  // Consumer code must call checkAnswer in order to validate answer
  onIncorrectItemSelected: Delegate<SelectorExerciseDelegate> = new Delegate<SelectorExerciseDelegate>();
  // Event raised when attempt is finished (for both success and failed cases)
  onAttemptFinished: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  // Event raised if student has taken too many retries/errors
  onFailed: Delegate<SelectorExerciseFailedDelegate> = new Delegate<SelectorExerciseFailedDelegate>();

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<boolean> {
    this.filter(filter);

    const shuffle = this.shuffle && (
        this.shuffle === ShuffleModeEnum.ShuffleOnStart ||
        this.shuffle === ShuffleModeEnum.ShuffleOnStartAndRepeat ||
        this.shuffle === ShuffleModeEnum.AlwaysShuffle
    );

    if (shuffle) {
      this.active = SysHelper.shuffleArray(this.active);
    }

    this._active = this.active;

    this.updateIndexAndLimit();

    await this.executeStart();

    return await this.startAtIndex(this.currentIndex);
  }

  async continue(): Promise<boolean> {
    // If either is true, move to the next child
    // Last answer is correct
    // Repetition is disabled
    // Repetition doesn't require the same child on fail
    const moveToTheNext = this._isLastCorrect || !this.repetition || (this.repetition !== RepetitionModeEnum.RepeatFailedUntilCorrect && this.repetition !== RepetitionModeEnum.RepeatFailedUntilCorrectAndSaveIncorrect);

    if (!this._isLastCorrect) {
      const repeat = this.repetition && (
        this.repetition === RepetitionModeEnum.RepeatFailedRandomly ||
        this.repetition === RepetitionModeEnum.RepeatFailedUntilCorrect ||
        this.repetition === RepetitionModeEnum.RepeatFailedUntilCorrectAndSaveIncorrect
      );
      const repeatEntirely = this.repetition && this.repetition === RepetitionModeEnum.RepeatFromBeginning;

      if (repeat) {
        this.currentItem.reset();
      } else if (repeatEntirely) {
        this.active.forEach(e => e.reset());
      }
    }

    const shuffle = this.shuffle && (
      this.shuffle === ShuffleModeEnum.ShuffleOnContinue ||
      this.shuffle === ShuffleModeEnum.AlwaysShuffle ||
      (!moveToTheNext && this.shuffle === ShuffleModeEnum.ShuffleOnStartAndRepeat) // stay at current index and shuffle needed
    );

    if (shuffle) {
      this.active = SysHelper.shuffleArray(this.active);
    }

    if (moveToTheNext) {
      this.updateIndexAndLimit();
    } else {
      // Updating index if we don't want to move to the next option
      // BUT the index might be changed if options were shuffled above (ShuffleOnStartAndRepeat option)
      this.currentIndex = this.active.indexOf(this.currentItem);
    }

    return await this.startAtIndex(this.currentIndex);
  }

  async repeat(filter?: LessonExerciseFilter): Promise<boolean> {
    this.reset();
    return await this.start(filter);
  }

  async stop(): Promise<void> {
    throw "Not implemented yet.";
  }

  override filter(filter?: LessonExerciseFilter):void {
    if ((!filter || !filter.keys || !filter.keys.length) || !this.isFilterChildren) {
      this.active = this.children;
    } else {
      this.active  = this.children.filter(e => filter.keys.some(key => key === e.text));
    }
  }

  override async startAtIndex(index: number): Promise<boolean> {
    // Fail logic for repetition until-correct, until-correct-and-save-incorrect, randomly, from-beginning
    const repeatAgain =
      this.repetition && (
        this.repetition === RepetitionModeEnum.RepeatFailedUntilCorrect ||
        this.repetition === RepetitionModeEnum.RepeatFailedUntilCorrectAndSaveIncorrect ||
        this.repetition === RepetitionModeEnum.RepeatFailedRandomly ||
        this.repetition === RepetitionModeEnum.RepeatFromBeginning
      );

    if (repeatAgain && !this._isLastCorrect) {
      // Attempt finished for above repetition modes
      //! NOTE: onAttemptFinished need testing
      await this.onAttemptFinished.execute(this);
      this.currentAttemptNumber++;

      // We save all user's incorrect answers(and fail lesson if there are any incorrect answers), but finish successfully exercise;
      if (this.incorrectAnswers.size > 0 && this.repetition === RepetitionModeEnum.RepeatFailedUntilCorrectAndSaveIncorrect) {
        await this.fillIncorrectAnswersAllTime();

        await this.onFailed.execute(this, this.incorrectAnswersAllTime);
      }

      // Failing exercise if user exceeded max number of attempts
      if (this.currentAttemptNumber >= this.maxNumberOfAttempts) {
        await this.executeFail();
        return false;
      }
    }
    // End of until-correct, until-correct-and-save-incorrect, randomly, from-beginning

    // Playing next sound
    const result = await super.startAtIndex(index);

    if (result) {
      return true;
    }

    // Finish logic for repetition until-correct, until-correct-and-save-incorrect, randomly, from-beginning
    if (repeatAgain) {
      await this.executeFinish();
      return false;
    }
    // End of until-correct, until-correct-and-save-incorrect, randomly, from-beginning

    // Logic for at-the-end
    const atTheEnd = this.repetition && this.repetition === RepetitionModeEnum.RepeatIncorrectAtTheEnd;
    if (atTheEnd) {
      // Attempt finished
      this.currentAttemptNumber++;
      await this.onAttemptFinished.execute(this);

      if (this.isGroupIncorrectAnswersByConType) {
        await this.groupIncorrectAnswersByConTypeAllTime();
        this.resetIncorrectAnswersByConType(this.conTypeIncorrectAnswersPerAttempt);
      }

      if (this.incorrectAnswers.size > 0) {
        await this.fillIncorrectAnswersAllTime();

        if (this.currentAttemptNumber >= this.maxNumberOfAttempts) {
          await this.executeFail();
           //! reseting currentAttemptNumber - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
          this.currentAttemptNumber = 0;
          return false;
        } else {
          const filter = new LessonExerciseFilter();
          filter.keys = [...this.incorrectAnswers].map(e => e.text);

          return await this.repeat(filter);
        }
      } else {
        await this.executeFinish();
        return false;
      }
    }
    // End of at-the-end

    // Finish/fail logic for no-repeat
    const noRepeat = !this.repetition || this.repetition === RepetitionModeEnum.NoRepeat;
    if (noRepeat) {
      if (this.incorrectAnswers.size > 0) {
        await this.executeFail();
      } else {
        await this.executeFinish();
      }
    }
    // End of no-repeat

    return false;
  }

  // We also terminate incentive sound on demand;
  override async terminateExercise() {
    await super.terminateExercise();

    await this._incentiveSoundsPlayer?.terminatePlayer();
    await this.active.forEach(e=>e.terminateExercise());

    this.reset();
  }

  override reset() {
    this.correctAnswers.clear();
    this.incorrectAnswers.clear();

    super.reset();
  }

  updateIndexAndLimit(): void {
    let active = this.limit ? this._active : this.active;
    // Find only items with audio and which weren't played/finished previously
    const excluded = active.filter(e => e.isFinished || !e.audio.length);
    let currentIndex = excluded.length !== active.length ?
      SysHelper.pickRandomIndex(active, excluded) :
      -1;

    if (this.limit && currentIndex !== -1) {
      const playableElelment = active[currentIndex];

      active = SysHelper.shuffleArray(active).slice(0, this.limit);

      if (active.indexOf(playableElelment) === -1) {
        const currentItemIndex = SysHelper.randomBetween(0, this.limit - 1);

        active.splice(
          currentItemIndex,
          1,
          playableElelment
        );
      }

      currentIndex = active.indexOf(playableElelment);
    }

    this.currentIndex = currentIndex;
    this.active = active;
  }

  // Check if answer is correct (the same as last played option)
  // Raises onCorrectItemSelected / onIncorrectItemSelected events and awaits for them
  async checkAnswer(answer: SelectorExerciseOption): Promise<void> {
    if (this.currentItem === answer) {
      this._isLastCorrect = true;
      this.correctAnswers.add(this.currentItem);

      console.warn(this.constructor.name + " (" + this.text + ") onCorrectItemSelected (" + answer.text + ").");
      await this.onCorrectItemSelected.execute(this, this.currentItem, answer);
    } else {
      this._isLastCorrect = false;
      this.incorrectAnswers.add(this.currentItem);

      if (this.isGroupIncorrectAnswersByConType) {
        this.groupIncorrectAnswersByConType(this.currentItem.text);
      }

      console.warn(this.constructor.name + " (" + this.text + ") onIncorrectItemSelected (" + answer.text + ").");
      await this.onIncorrectItemSelected.execute(this, this.currentItem, answer);
    }
  }

  async fillIncorrectAnswersAllTime(): Promise<void> {
    await this.incorrectAnswers.forEach(e => {
      this.incorrectAnswersAllTime.add(e);
    });
  }

  async playIncentiveSound(): Promise<void> {
    if (!this._incentiveSoundsPlayer) {
      this._incentiveSoundsPlayer = new SoundPlayer(this.incentiveSounds);
    }

    const soundIndex = SysHelper.randomBetween(0, this.incentiveSounds.length - 1);

    await this._incentiveSoundsPlayer.play(soundIndex);
  }

  // Methods to handle logic for incorrect answers per con of syllables(con "a", con "e", con "i");

  // answerText is temp decision, we will get type from syllable configuration later
  async groupIncorrectAnswersByConType(answerText: string): Promise<any> {
    const answerTextConType = answerText.slice(-1);

    // Fill incorrect answers to conTypeIncorrectAnswersPerAttempt(con "a", con "e", con "i") per user incorrect answer
    for (const conType in this.conTypeIncorrectAnswersPerAttempt) {
      // We check if we have this con type and if anwer con type is one of that cons
      if (SysHelper.hasKey(this.conTypeIncorrectAnswersPerAttempt, conType) && conType === answerTextConType) {
        this.conTypeIncorrectAnswersPerAttempt[conType]++;
      }
    }
  }

  // We check all incorrect answers after attempt and set them to object per all time(conTypeIncorrectAnswersAllTime)
  groupIncorrectAnswersByConTypeAllTime(): void {
    for (const conType in this.conTypeIncorrectAnswersPerAttempt) {
      if (SysHelper.hasKey(this.conTypeIncorrectAnswersPerAttempt, conType) && this.conTypeIncorrectAnswersPerAttempt[conType] > 0) {
        this.conTypeIncorrectAnswersAllTime[conType]++;
      }
    }
  }

  // Fail if current attempt is last attempt per exercise
  handleFailExerciseWithConType(answerText: string): any {
    const answerTextConType = answerText.slice(-1);

    if (SysHelper.hasKey(this.conTypeIncorrectAnswersAllTime, answerTextConType) && this.conTypeIncorrectAnswersAllTime[answerTextConType] >= this.maxNumberOfAttempts - 1) {
      //! Clear previous exercise state - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
      this.active = [];
      this.reset();
      this.resetIncorrectAnswersByConType(this.conTypeIncorrectAnswersPerAttempt);
      this.resetIncorrectAnswersByConType(this.conTypeIncorrectAnswersAllTime);
      this.currentAttemptNumber = 0;

      return answerTextConType;
    }

    return;
  }

  // Reseting(setting to zzero) incorrect answers per attempt/all time when called;
  resetIncorrectAnswersByConType(object: IConTypeIncorrectAnswers): void {
    Object.entries(object).forEach(([key]) => {
      if (SysHelper.hasKey(object, key)) {
        object[key] = 0;
      }
    });
  }

  // End of methods to handle checking incorrect answers per con of syllables(con "a", con "e", con "i");
  async executeFail(): Promise<void> {
    console.warn(this.constructor.name + " (" + this.text + ") onFailed.");
    await this.onFailed.execute(this, this.incorrectAnswersAllTime);

    await this.executeFinish();
  }
}