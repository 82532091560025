export class LessonExerciseFilter {
  constructor(
    public keys: string[] = [],
    /*
     * Functionality for this property is not implemented on Lesson/Exercise levels
     * Since it's not used at the moment
     */
    public child: LessonExerciseFilter | undefined = undefined
  ) {}
}
