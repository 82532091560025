import "@mdi/font/css/materialdesignicons.min.css";
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#e97c17",
        secondary: "#cf3d2e",
        accent: "#d21e0f",
        anchor: "#3f51b5",
        grey: "#d9d9d9",
        grey2: "#565656",
        error: "#b71c1c",
        success: "#1ec93c",
        "light-orange": "#e7ad4b"
      }
    },

    options: {
      customProperties: true
    }
  }

  // For right now, loading all icons in main.js
  // icons: {
  //   iconfont: "mdi",
  //   values: {
  //     calendar: "mdi-calendar"
  //   }
  // }
});
