import { render, staticRenderFns } from "./Syllable.vue?vue&type=template&id=5ec25a21&scoped=true&"
import script from "./Syllable.vue?vue&type=script&lang=ts&"
export * from "./Syllable.vue?vue&type=script&lang=ts&"
import style0 from "./Syllable.vue?vue&type=style&index=0&id=5ec25a21&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec25a21",
  null
  
)

export default component.exports