



















import { defineComponent } from "@vue/composition-api";
import { ProgramSelectorItem } from "@/ts/ProgramSelectorItem";

export default defineComponent({
  components: {},

  props: {},

  setup() {
    const programs: ProgramSelectorItem[] = [
      new ProgramSelectorItem(
        "módulos de Sonidos",
        "#d21e0f",
        require("@/assets/kids/9.jpg"),
        "/sonidos"
      ),
      new ProgramSelectorItem(
        "módulos de combinación",
        "#e97100",
        require("@/assets/kids/10.jpg"),
        "/combined-modules"
      )
    ];

    return {
      programs
    };
  }
});
