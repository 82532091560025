import { Type } from "class-transformer";
import { Sound } from "@/ts/sounds/Sound";
import { LessonInstructions } from "@/ts/domain/lessons/LessonInstructions";
import { SoundPlayer } from "@/ts/sounds/SoundPlayer";
import { Delegate } from "@/ts/system/Delegate";

export type ExerciseDelegate = (exercise: BaseExercise) => Promise<any>;

// Provides base functionality for simple exercises/parts such as syllables, options, etc
export abstract class BaseExercise {
  // Server props
  readonly beforeText: string = "";
  readonly text: string = "";

  @Type(() => LessonInstructions)
  readonly instructions: LessonInstructions = new LessonInstructions();

  @Type(() => Sound)
  readonly audio: Sound[] = [];

  @Type(() => Sound)
  readonly afterAudio: Sound[] = [];

  // Frontend props
  // Set to true during the instructions sound, otherwise - false.
  isDisabled: boolean = false;

  // Switching to true/false before/after playing associated sounds.
  isPlaying: boolean = false;

  // True once exercise is finished.
  isFinished: boolean = false;

  private _instructionsPlayer?: SoundPlayer;
  private _audioPlayer?: SoundPlayer;
  private _afterAudioPlayer?: SoundPlayer;
  private _terminateFinish: boolean = false;

  // Delegates
  // Event raised before exercise is started
  onBeforeStarted: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  // Event raised after instructions and exercise's audio were played
  onStarted: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  // Event raised before exercise finish <br />
  // Consumer code can call stopFinish in order to prevent exercise finish
  onBeforeFinished: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  // Event raised on exercise finish
  onFinished: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();
  // Event raised if consumer code called stopFinish
  onFinishStopped: Delegate<ExerciseDelegate> = new Delegate<ExerciseDelegate>();

  // Methods
  abstract start(): Promise<void | boolean>;
  abstract repeat(): Promise<void | boolean>;
  abstract stop(): Promise<void | boolean>;

  async terminateExercise() {
    await this._instructionsPlayer?.terminatePlayer();
    await this._audioPlayer?.terminatePlayer();
    await this._afterAudioPlayer?.terminatePlayer();

    this.reset();
  }

  reset(): void {
    this.isDisabled = false;
    this.isPlaying = false;
    this.isFinished = false;
  }

  async playSound(): Promise<any> {
    if (!this._audioPlayer) {
      this._audioPlayer = new SoundPlayer(this.audio);
    }

    this.isPlaying = true;
    try {
      return await this._audioPlayer.playRecursively();
    } finally {
      this.isPlaying = false;
    }
  }

  async playAfterSound(): Promise<any> {
    if (!this._afterAudioPlayer) {
      this._afterAudioPlayer = new SoundPlayer(this.afterAudio);
    }

    this.isPlaying = true;
    try {
      return await this._afterAudioPlayer.playRecursively();
    } finally {
      this.isPlaying = false;
    }
  }

  async playInstructions(): Promise<any> {
    if (!this._instructionsPlayer) {
      this._instructionsPlayer = new SoundPlayer(this.instructions.audio);
    }

    return await this._instructionsPlayer.playRecursively();
  }

  // Executes actions in order to start exercise properly
  protected async executeStart() {
    console.warn(this.constructor.name + " (" + this.text + ") onBeforeStarted.");
    await this.onBeforeStarted.execute(this);

    this.isDisabled = true;
    await this.playInstructions();
    await this.playSound();
    this.isDisabled = false;

    console.warn(this.constructor.name + " (" + this.text + ") onStarted.");
    await this.onStarted.execute(this);
  }

  // Executes actions in order to finish exercise properly <br />
  // Returns true if exercise finished successfully <br />
  // Returns false if exercise finish was terminated
  protected async executeFinish(): Promise<boolean> {
    console.warn(this.constructor.name + " (" + this.text + ") onBeforeFinished.");
    await this.onBeforeFinished.execute(this);

    if (this._terminateFinish) {
      this._terminateFinish = false;

      console.warn(this.constructor.name + " (" + this.text + ") onFinishTerminated.");
      await this.onFinishStopped.execute(this);
      return false;
    }

    this.isFinished = true;
    await this.playAfterSound();

    console.warn(this.constructor.name + " (" + this.text + ") onFinished.");
    await this.onFinished.execute(this);

    return true;
  }

  // Stops finish sequence right after onBeforeFinished raised
  protected stopFinish() {
    this._terminateFinish = true;
  }
}
