
















import { defineComponent, reactive, ref, Ref } from "@vue/composition-api";
import { useRouter } from "@/ts/composition/vue-router";
import { useUserStore } from "@/store/useUserStore";
import { useLoginHelper } from "@/ts/composition/useLoginHelper";
import { LoginImageWithKey } from "@/ts/domain/LoginImageWithKey";
import LoginScreenPart1 from "@/components/login/LoginScreenPart1.vue";
import LoginScreenPart2 from "@/components/login/LoginScreenPart2.vue";
import { useJwtTokenProvider } from "@/ts/composition/useJwtTokenProvider";

export default defineComponent({
  components: {
    LoginScreenPart1,
    LoginScreenPart2
  },

  props: {},

  setup() {
    // Fields
    const router = useRouter();
    const userStore = useUserStore();
    const images = ref<LoginImageWithKey[]>([]);
    const { generateImages } = useLoginHelper();
    let correctKey = 0;

    const state = reactive({
      step: 1,
      errors: userStore.errors,
      images: images
    });

    // Methods
    function loginPart1(studentsName: Ref<string>, className: Ref<string>): void {
      userStore.login(studentsName.value, className.value).then(() => {
        const token = useJwtTokenProvider().tokenContent.value;
        correctKey = Number(token?.LoginKey ?? 0);
        images.value = generateImages(correctKey);
        state.step = 2;
      });
    }

    function loginPart2(key: number): void {
      userStore
        .validateKey(key)
        .then(() => {
          router.push("/");
        })
        .catch(() => {
          images.value = generateImages(correctKey);
        });
    }

    function loginCancel(): void {
      userStore.logout().then(() => {
        router.go(0);
      });
    }

    return {
      state,

      loginPart1,
      loginPart2,
      loginCancel
    };
  }
});
