import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { BaseLesson } from "@/ts/domain/BaseLesson";

export class Lesson extends BaseLesson {
  // Server props
  autoplay: boolean = true;

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<any> {
    this.lessonFilter = filter;
    this.filter(filter);

    await this.executeStart();

    if (this.autoplay) {
      return await this.startRecursively(0, filter);
    } else {
      return await this.startAtIndex(0, filter);
    }
  }

  async nextExercise(): Promise<any> {
    if (this.autoplay) {
      return await this.startRecursively(++this.currentIndex, this.lessonFilter);
    } else {
      return await this.startAtIndex(++this.currentIndex, this.lessonFilter);
    }
  }

  protected async startAtIndex(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    const result = await super.startAtIndex(index, filter);
    if (result) {
      return true;
    }

    await this.executeFinish();
    return false;
  }
}
