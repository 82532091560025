import { BaseLesson } from "@/ts/domain/BaseLesson";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

export class GridLesson extends BaseLesson {
  // Server props
  // Array of rows where each number representing count of coluns in that row(e.g. we have 4 columns in first row and 3 columns in second ==> [4, 3]);
  rowsColumnsCount: number[] = [];

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<any> {
    this.lessonFilter = filter;
    this.filter(filter);

    await this.executeStart();
    await this.startRecursively(0, filter);
    await this.executeFinish();
  }

  async nextExercise(): Promise<any> {
    throw "Not supported.";
  }
}
