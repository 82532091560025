import { Type } from "class-transformer";
import { Module } from "@/ts/domain/Module";

export class Program {
  // Server props
  id: string = "";
  name: string = "";
  description: string = "";
  totalModules: number = 0;

  @Type(() => Module)
  modules: Module[] = [];
}
