import { Type } from "class-transformer";
import { GridExerciseSyllable } from "@/ts/domain/exercises/GridExerciseSyllable";
import { BaseCompositeExercise } from "@/ts/domain/exercises/BaseCompositeExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

/*
  <b>Automatically</b> plays all active syllables
 */
export class GridExercise extends BaseCompositeExercise<GridExerciseSyllable> {
  // Server props
  @Type(() => GridExerciseSyllable)
  declare readonly children: GridExerciseSyllable[];
  declare exerciseGridStructure: number[];

  // Frontend props

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<boolean> {
    this.filter(filter);
    await this.executeStart();
    await this.startFromIndexRecursively(0);
    await this.executeFinish();

    return false;
  }

  async stop(): Promise<void> {
    throw "Not implemented yet.";
  }

  async continue(): Promise<boolean> {
    return await this.startFromIndexRecursively(++this.currentIndex);
  }

  async repeat(filter?: LessonExerciseFilter): Promise<boolean> {
    this.reset();
    return await this.start(filter);
  }

  override filter(filter?: LessonExerciseFilter): void {
    if (!filter || !filter.keys || !filter.keys.length) {
      this.active = this.children.filter(e => e.isActive);
      return;
    }

    this.active = [];

    for (const childrenKey in this.children) {
      const child = this.children[childrenKey];
      const isIncluded = filter.keys.some(e => e === child.text);

      child.isFilteredOut = !isIncluded;
      if (isIncluded && child.isActive) {
        this.active.push(child);
      }
    }
  }
}
