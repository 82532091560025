export interface ITimer {
  resume(): void;
  pause(): void;
  restart(): void;
}

export class Timer implements ITimer {
  protected timerId: number = 0;
  protected start: number = 0;
  protected remaining: number = 0;

  protected readonly args: Array<any>;
  protected readonly onStartFunc: Function;
  protected readonly onFinishFunc: Function;

  constructor(
    onStart: Function | null,
    onFinish: Function | null,
    protected readonly delay: number,
    ...args: any[]
  ) {
    this.onStartFunc = onStart ? onStart : () => {};
    this.onFinishFunc = onFinish ? onFinish : () => {};
    this.args = args;

    this.restart();
  }

  pause(): void {
    window.clearTimeout(this.timerId);
    this.remaining -= Date.now() - this.start;
  }

  resume(): void {
    this.start = Date.now();
    window.clearTimeout(this.timerId);
    this.timerId = window.setTimeout(this.onFinishFunc, this.remaining, ...this.args);
  }

  restart(): void {
    this.remaining = this.delay;
    this.onStartFunc(...this.args);

    this.resume();
  }

  terminate(): void {
    window.clearTimeout(this.timerId);
  }
}
