
































import { defineComponent, computed, ref, Ref } from "@vue/composition-api";
import { IExercise } from "@/ts/vue/exercises/IExercise";
import SyllablesConcatenatorSequence from "@/components/exercises/SyllablesConcatenatorSequence.vue";
import SyllablesConcatenator from "@/components/exercises/SyllablesConcatenator.vue";
import { plainToClass } from "class-transformer";
import { ConcatenatorSequenceExercise } from "@/ts/domain/exercises/ConcatenatorSequenceExercise";

export default defineComponent({
  components: {
    SyllablesConcatenatorSequence,
    SyllablesConcatenator
  },

  setup() {
    const componentRef: Ref<IExercise | undefined> = ref();
    const tmp = {
      type: "concatenator-exercise-sequence",
      text: "root",
      children: [
        {
          text: "ma",
          audio: [
            {
              src: "blending/module1/lesson3/ma, ma, ma.m4a",
              duration: 4000
            }
          ],
          parts: [
            {
              text: "m",
              audio: [
                {
                  src: "blending/module1/m.m4a",
                  duration: 2000
                }
              ]
            },
            {
              text: "a",
              audio: [
                {
                  src: "blending/module1/a.m4a",
                  duration: 2000
                }
              ]
            }
          ]
        },
        {
          text: "pa",
          audio: [
            {
              src: "blending/module1/lesson3/pa, pa, pa.m4a",
              duration: 4000
            }
          ],
          parts: [
            {
              text: "p",
              audio: [
                {
                  src: "blending/module1/p.m4a",
                  duration: 2000
                }
              ]
            },
            {
              text: "a",
              audio: [
                {
                  src: "blending/module1/a.m4a",
                  duration: 2000
                }
              ]
            }
          ]
        },
        {
          text: "sa",
          audio: [
            {
              src: "blending/module1/lesson3/sa, sa, sa.m4a",
              duration: 4000
            }
          ],
          parts: [
            {
              text: "s",
              audio: [
                {
                  src: "blending/module1/s.m4a",
                  duration: 2000
                }
              ]
            },
            {
              text: "a",
              audio: [
                {
                  src: "blending/module1/a.m4a",
                  duration: 2000
                }
              ]
            }
          ]
        },
        {
          text: "la",
          audio: [
            {
              src: "blending/module1/lesson3/la, la, la.m4a",
              duration: 4000
            }
          ],
          parts: [
            {
              text: "l",
              audio: [
                {
                  src: "blending/module1/l.m4a",
                  duration: 2000
                }
              ]
            },
            {
              text: "a",
              audio: [
                {
                  src: "blending/module1/a.m4a",
                  duration: 2000
                }
              ]
            }
          ]
        }
      ]
    };

    const exercise = plainToClass(ConcatenatorSequenceExercise, tmp);

    function restart() {
      componentRef.value?.repeatExercise();
    }

    return {
      componentRef,

      exercise,

      restart
    };
  }
});
