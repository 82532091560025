






































import { defineComponent, PropType, reactive } from "@vue/composition-api";

import { ConcatenatorSequenceExercise } from "@/ts/domain/exercises/ConcatenatorSequenceExercise";

import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import SyllablesConcatenator from "@/components/exercises/SyllablesConcatenator.vue";
import Syllable from "@/components/exercises/Syllable.vue";

import { delay } from "@/ts/system/delay";

/**
 * Implements logic for
 */
export default defineComponent({
  components: {
    Syllable,
    SyllablesConcatenator
  },

  props: {
    exercise: {
      type: Object as PropType<ConcatenatorSequenceExercise>,
      required: true
    },
    exerciseFilter: {
      type: Object as PropType<LessonExerciseFilter>,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    props.exercise.onItemFinished.add(async () => {
      state.isExerciseHidden = true;
      await delay(300);
      state.isExerciseHidden = false;
    });

    props.exercise.onFinished.add(async () => {
      finishExercise();
    });

    const state = reactive({
      isExerciseHidden: false
    });

    function playSound(): Promise<any> {
      if (props.exercise.currentItem) {
        return props.exercise.currentItem.playSound();
      }

      return Promise.resolve();
    }

    function startExercise(): void {
      props.exercise.moveHorizontally = false;
      props.exercise.start(props.exerciseFilter);
    }

    function repeatExercise(): void {
      props.exercise.repeat(props.exerciseFilter);
    }

    function repeatCurrentItem(): void {
      props.exercise.startAtIndex(props.exercise.rowIndex, props.exercise.columnIndex);
    }

    function continueExercise() {
      props.exercise.continue();
    }

    function finishExercise(): void {
      emit("exercise-finished");
    }

    function failExercise(): void {
      emit("exercise-failed");
    }

    return {
      state,

      playSound,
      startExercise,
      continueExercise,
      repeatExercise,
      repeatCurrentItem
    };
  }
});
