



























import { defineComponent, computed } from "@vue/composition-api";
import ProgramSelector from "@/components/ProgramSelector.vue";
import EarnedStars from "@/components/EarnedStars.vue";
import { useUserStore } from "@/store/useUserStore";
import { useBlendingStore } from "@/store/useBlendingStore";

export default defineComponent({
  components: {
    ProgramSelector,
    EarnedStars
  },

  setup() {
    const userStore = useUserStore();
    const blendingStore = useBlendingStore();

    const fullName = computed(() => {
      const info = userStore.userInfo.value;

      return info.FirstName + " " + info.LastName;
    });

    const stars = blendingStore.starsCount;

    return {
      fullName,
      stars
    };
  }
});
