export enum RepetitionModeEnum {
  // No repetition for wrong/failed answers.
  NoRepeat = "no-repeat",
  // Repeat wrong/failed answers until it's correct. <br />
  // Let's say, correct answer is "ma", student chooses "pa", then app will repeat "ma" until user selects that. <br />
  // Resets failed child state.
  RepeatFailedUntilCorrect = "until-correct",
  // Repeat wrong/failed answers until it's correct and save incorrect answers to fail exercise after finished.<br />
  // Let's say, correct answer is "ma", student chooses "pa", then app will repeat "ma" until user selects that. <br />
  // Resets failed child state.
  // !This enum will add incorrect answers to localStorage and set filter with it, so make sure to add "clearExerciseFilter", if you won't need to save incorrect answers <br />
  // Example: Lesson 1.2.5 and 1.2.6 share same data(lesson5-data.json) and for 1.2.6 we need to use "until-correct-and-save-incorrect" enum to save incorrect answers, but for 1.2.5 we did not need it, so we should clear filter after finnish of 1.2.5 lesson.
  RepeatFailedUntilCorrectAndSaveIncorrect = "until-correct-and-save-incorrect",
  // Repeat wrong/failed answer after other children. <br />
  // Let's say, correct answer is "ma", student chooses "pa", then app will play "pa", "sa" or any other children. <br />
  // Then at random moment it will repeat "ma" and keep playing "ma" at random moments until user selects it correctly. <br />
  // Resets failed child state.
  RepeatFailedRandomly = "randomly",
  // Repeat an entire exercise if a student has mistaken
  RepeatFromBeginning = "from-beginning",
  // Repeat exercise with all wrong answers
  RepeatIncorrectAtTheEnd = "repeat-incorrect-at-the-end"
}

export enum ShuffleModeEnum {
  // Do not shuffle children.
  NoShuffle = "no-shuffle",
  // Shuffles on start, continue and item repeat actions.
  AlwaysShuffle = "always",
  // Shuffles on start.
  ShuffleOnStart = "on-start",
  // Shuffles on start and item repeat action.
  ShuffleOnStartAndRepeat = "on-start-and-repeat",
  // Shuffles on continue action.
  ShuffleOnContinue = "on-continue"
}

export enum IncorrectAnswerActionEnum {
  Continue = "continue",
  Repeat = "repeat",
  RepeatItem = "repeat-item"
}

export enum ExerciseActionEnum {
  CorrectOption = "correct-option",
  IncorrectOption = "incorrect-option"
}

export enum ExerciseAnswersShowEnum {
  ShowOnExerciseFinish = "show-on-exercise-finish",
  ShowOnLessonFinish = "show-on-lesson-finish"
}

export enum LessonFinishedActionType {
  RepeatLesson = "repeat-lesson",
  RepeatExercise = "repeat-exercise",
  Redirect = "redirect"
}

// Enum with types of con(con "a", con "e", con "i", con "o", con "u")
export enum SyllableConTypeEnum {
  A = "a",
  E = "e",
  I = "i",
  O = "o",
  U = "u"
}
