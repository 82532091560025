import { SyllableExercise } from "@/ts/domain/exercises/SyllableExercise";

export class GridExerciseSyllable extends SyllableExercise {
  // Server props

  // Server declares if syllable should/shouldn't be played based on lesson. <br />
  // Grid could have 5 rows of syllables but only one row may has to be played in this particular lesson.
  isActive: boolean = false;

  // Temp decision on adding inverted color to syllable
  isInverted: boolean = false;

  // Frontend props

  // Indicates if this syllable is filtered out by outer filter. <br />
  // Parent class must control this setting.
  isFilteredOut: boolean = false;
}
