import { LessonTargetUrl } from "@/ts/domain/lessons/LessonTargetUrl";
import { LessonFinishedActionType } from "@/ts/domain/Enums";

export class LessonFinishedAction {
  constructor(
    public type: LessonFinishedActionType = LessonFinishedActionType.RepeatExercise,
    public target: LessonTargetUrl | undefined = undefined
  ) {
    this.type = type;
    this.target = target;
  }
}
