import { getCurrentInstance, watch, reactive, toRefs, Ref } from "@vue/composition-api";
import { Route, VueRouter } from "vue-router/types/router";
import { LoggerService } from "@/ts/system/logger/LoggerService";

/*
 * Returns an active/current route as Ref<Route>
 */
export function useRoute(): Ref<Route> {
  const vm = getCurrentInstance();
  if (!vm) {
    LoggerService.error("Must be called in setup.", "vue-router");
    throw new Error("Must be called in setup.");
  }

  const state = reactive({
    route: vm.proxy.$route
  });

  watch(
    () => vm.proxy.$route,
    newRoute => {
      state.route = newRoute;
    }
  );

  return toRefs(state as any).route;
}

/*
 * Returns VueRouter
 */
export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) {
    LoggerService.error("Must be called in setup.", "vue-router");
    throw new Error("Must be called in setup.");
  }

  return vm.proxy.$router;
}
