import { Type } from "class-transformer";

import { Delegate } from "@/ts/system/Delegate";

import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";

import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { BaseCompositeExercise } from "@/ts/domain/exercises/BaseCompositeExercise";
import { SelectorExerciseOption } from "@/ts/domain/exercises/SelectorExerciseOption";

export type SelectorSequenceExerciseFailedDelegate = (exercise: SelectorSequenceExercise, wrongAnswers: Set<SelectorExerciseOption>) => Promise<any>;

// Doesn't support autoplay
export class SelectorSequenceExercise extends BaseCompositeExercise<SelectorExercise> {
  // Server props
  @Type(() => SelectorExercise)
  public children: SelectorExercise[] = [];

  placeholder: string = "";
  // Check if we need to filter children of current exercise
  isFilterChildren: boolean = false;

  // Frontend props
  get correctText(): string {
    return this.children.map(e => e.correctText).join('');
  }

  private incorrectAnswersAllTime: Set<SelectorExerciseOption> = new Set<SelectorExerciseOption>();

  protected workflow: any = {
    onSelectorFailed: () => {}
  }

  // Delegates
  // Raised if at least one of children SelectorExercises has failed
  onFailed: Delegate<SelectorSequenceExerciseFailedDelegate> = new Delegate<SelectorSequenceExerciseFailedDelegate>()

  // Constructors
  constructor() {
    super();

    this.workflow.onSelectorFailed = async (selector: SelectorExercise, wrongAnswers: Set<SelectorExerciseOption>) => {
      wrongAnswers.forEach(e => {
        this.incorrectAnswersAllTime.add(e);
      })
    }
  }

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<boolean> {
    this.filter(filter);

    await this.executeStart();

    return await this.startAtIndex(0, filter);
  }

  async continue(): Promise<boolean> {
    // SelectorExercise doesn't play all sounds automatically
    // So, we play them one by one
    // Try to continue playing current SelectorExercise first (next sound)
    let result = await this.currentItem.continue();

    if (!result) {
      // If current selector finished, switch to the next
      result = await this.startAtIndex(++this.currentIndex)
    }

    return result;
  }

  async repeat(filter?: LessonExerciseFilter): Promise<boolean> {
    this.reset();

    return await this.start(filter);
  }

  async stop(): Promise<void> {
    throw "Not implemented yet.";
  }

  //! adding new reset - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
  override reset() {
    this.incorrectAnswersAllTime.clear();
    this.children.forEach(e => e.reset());

    super.reset();
  }

  override filter(filter?: LessonExerciseFilter): void {
    if ((!filter || !filter.keys || !filter.keys.length) || !this.isFilterChildren) {
      this.active = this.children;
    } else {
      this.active = this.children.filter(e => filter.keys.some(key => key === e.text));
    }
  }

  override async startAtIndex(index: number, filter?: LessonExerciseFilter): Promise<boolean> {
    this.currentIndex = index;
    const exercise = this.currentItem;

    // If we have next SelectorExercise
    if (exercise) {
      this.bindExerciseEvents(exercise, index);

      await exercise.start(filter);

      return true;
    }

    // No next SelectorExercise
    if (this.incorrectAnswersAllTime.size !== 0) {
      console.warn(this.constructor.name + " onFailed.");
      await this.onFailed.execute(this, this.incorrectAnswersAllTime);
    }

    // Finish sequence exercise
    await this.executeFinish();

    return false;
  }

  protected override bindExerciseEvents(exercise: SelectorExercise, index: number) {
    exercise.onFailed.add(this.workflow.onSelectorFailed);

    super.bindExerciseEvents(exercise, index);
  }
}
