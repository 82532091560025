






























import { defineComponent } from "@vue/composition-api";
// import TheMenu from "@/components/singletons/TheMenu";
import TheLeftPanelLayout from "@/components/singletons/TheLeftPanelLayout.vue";
// import TheFooter from "@/components/singletons/TheFooter";

export default defineComponent({
  components: {
    TheLeftPanelLayout
    // TheMenu,
    // TheFooter
  },

  props: {
    showLeftPanel: Boolean
  },

  setup() {}
});
