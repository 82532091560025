import { Type } from "class-transformer";
import { Segment } from "@/ts/domain/Segment";

export class Module {
  // Server props
  id: string = "";
  name: string = "";
  sequenceNumber: number = 0;
  description: string = "";
  image: string = "";
  isCompleted: boolean = false;
  isInProgress: boolean = false;
  totalLessons: number = 0;
  totalSegments: number = 0;

  progress: any = {};

  @Type(() => Segment)
  segments: Segment[] = [];
}
