import { Type } from "class-transformer";
import { Sound } from "@/ts/sounds/Sound";
import { LessonFinishedAction } from "@/ts/domain/lessons/LessonFinishedAction";

export class LessonFinishedData {
  public text: string;

  @Type(() => Sound)
  public audio: Sound[];

  @Type(() => LessonFinishedAction)
  public action: LessonFinishedAction | undefined;

  constructor(
    text: string = "",
    action: LessonFinishedAction | undefined = undefined,
    audio: Sound[] = []
  ) {
    this.text = text;
    this.action = action;
    this.audio = audio;
  }
}
