import { JwtTokenContent } from "@/ts/JwtTokenContent";
import { JwtParser } from "@/ts/system/JwtParser";

import CONSTANTS from "@/constants/index";

export class JwtTokenProvider {
  public static getToken(): string {
    return localStorage.getItem(CONSTANTS.AUTH_STORAGE_KEY) ?? "";
  }

  public static setToken(value: string): void {
    localStorage.setItem(CONSTANTS.AUTH_STORAGE_KEY, value);
  }

  public static getTokenContent(): JwtTokenContent | undefined {
    return JwtParser.parse(this.getToken());
  }

  public static isAuthenticated(): boolean {
    return !!this.getToken();
  }
}
