import { reactive, ref, toRefs, Ref } from "@vue/composition-api";

import CONSTANTS from "@/constants/index";

import { useApiWithAuth } from "@/ts/composition/useApi";
import { useJwtTokenProvider } from "@/ts/composition/useJwtTokenProvider";
import { useRouter } from "@/ts/composition/vue-router";

import { Module } from "@/ts/domain/Module";
import { Program } from "@/ts/domain/Program";
import { Config } from "@/ts/system/Config";
import { LessonsFactory } from "@/ts/LessonsFactory";
import { LoggerService } from "@/ts/system/logger/LoggerService";
import { IStore } from "@/ts/vue/IStore";

const LESSONS_SERVICE_URL = Config.get().lessonsApiUrl;

let state: IBlendingStore;

export function useBlendingStore(): IBlendingStore {
  if (state) {
    return state;
  }

  const api = useApiWithAuth(LESSONS_SERVICE_URL);
  const router = useRouter();

  const data = ref<Module[]>([]);
  const errors = ref();
  const isLoaded = ref(false);
  const starsCount = ref(0);

  function load(): Promise<Module[]> {
    if (isLoaded.value) {
      return Promise.resolve(data.value as Module[]);
    }

    const factory = new LessonsFactory("blending");
    const query = {
      programId: CONSTANTS.BLENDING_GUID
    };

    // let result1 = response.data.map(t1 => {
    //   foreach
    //   const lessons = t1.map
    //   return null;
    // });

    // let result = localLessons.map(t1 => ({
    //   ...t1,
    //   ...serverLessons.data.find(t2 => t2.id === t1.id)
    // }));

    return new Promise((resolve, reject) => {
      const start = Number(new Date());
      const localPromise = factory.loadProgram().finally(() => {
        const diff = Number(new Date()) - start;
        LoggerService.debug(
          "Local lessons data fetched in " + diff / 1000 + "sec (" + diff + "ms).",
          "BLENDING API"
        );
      });

      const serverPromise = api.get(CONSTANTS.DATA_URL, query).catch(e => {
        if (e.response.status === 401) {
          const tokenProvider = useJwtTokenProvider();

          tokenProvider.setToken("");
          localStorage.setItem(CONSTANTS.CORRECT_KEY_SELECTED_KEY, "");

          return router.push("/login");
        }
        errors.value = e;
        throw e;
      });

      Promise.all([serverPromise, localPromise]).then(results => {
        //let serverResult = (results[0] as Program).modules;
        let localResult = (results[1] as Program).modules;

        fillResults(localResult);
        LoggerService.debug(localResult, "BLENDING API");
        // @ts-ignore
        window.__modules = localResult;

        // @ts-ignore
        localResult = toRefs(reactive(localResult));

        data.value = localResult;
        isLoaded.value = true;
        starsCount.value = getStarsCount();

        resolve(localResult);
      });
    });
  }

  function fillResults(result: Module[]): void {
    for (let i = 0; i < result.length; i++) {
      const segments = result[i].segments;
      let moduleTotalLessons = 0;
      let moduleCompletedLessons = 0;

      for (let j = 0; j < segments.length; j++) {
        const segment = segments[j];
        const segmentTotalLessons = segment.lessons.length;
        const segmentCompletedLessons = segment.lessons.filter(e => e.isCompleted).length;

        moduleTotalLessons += segmentTotalLessons;
        moduleCompletedLessons += segmentCompletedLessons;

        segment.progress = {
          totalLessons: segmentTotalLessons,
          completedLessons: segmentCompletedLessons
        };
      }
      result[i].progress = {
        totalLessons: moduleTotalLessons,
        completedLessons: moduleCompletedLessons
      };
    }
  }

  function markLessonAsComplete(lessonId: string) {
    return api
      .post(CONSTANTS.COMPLETE_LESSON_URL, '"' + lessonId + '"')
      .then(response => {
        updateModel(lessonId);
        starsCount.value = getStarsCount();
        return response;
      })
      .catch(e => {
        errors.value = e;
        throw e;
      });
  }

  function getStarsCount(): number {
    return data.value.filter(
      module => module.progress.totalLessons === module.progress.completedLessons
    ).length;
  }

  function updateModel(lessonId: string): boolean {
    let found = false;
    const modules = data.value;

    for (const moduleKey in modules) {
      const segments = modules[moduleKey].segments;
      for (const segmentKey in segments) {
        const lessons = segments[segmentKey].lessons;
        for (const lessonKey in lessons) {
          if (lessons[lessonKey].id === lessonId) {
            lessons[lessonKey].isCompleted = true;
            found = true;
          }
        }

        if (found) {
          // @ts-ignore
          segments[segmentKey].progress.completedLessons++;
          break;
        }
      }

      if (found) {
        modules[moduleKey].progress.completedLessons++;
        break;
      }
    }

    return found;
  }

  state = {
    data,
    errors,

    starsCount,

    isLoaded: isLoaded,
    isLoading: api.isLoading,
    activeRequestsCount: api.activeRequestsCount,

    markLessonAsComplete,
    load
  } as IBlendingStore;

  return state;
}

export interface IBlendingStore extends IStore<Module[]> {
  starsCount: Ref<number>;

  markLessonAsComplete(id: string): Promise<any>;
  load(): Promise<any>;
  isLoaded: Ref<boolean>;
}
