import { Timer } from "@/ts/system/Timer";
import { LoggerService } from "@/ts/system/logger/LoggerService";

export class Sound {
  private _audio: HTMLAudioElement | undefined;
  private _timer: Timer | undefined;

  constructor(
    public src: string = "",
    public duration: number | undefined = undefined,
    public waitAfter: number | undefined = undefined,
    public volume: number | undefined = undefined
  ) {}

  public play(): Promise<string> {
    if (!this.src) {
      return Promise.resolve("");
    }

    return new Promise<string>(resolve => {
      this._audio = new Audio(require(`@/assets/media/${this.src}`));
      this._audio.addEventListener("loadeddata", () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
        if (this._audio?.readyState === 4) {
          this._audio.volume = this.volume ? this.volume : 1;
          const duration = this.duration ?? 0;
          const pause = this.waitAfter ?? 0;
          const src = this.src;

          const msg = `Fixed duration: ${duration}; wait after: ${pause}; sound duration: ${this._audio.duration}; src: ${this.src}`;
          LoggerService.debug(msg, "Sound.ts");

          // If duration is set then resolve final promise after the delay
          if (duration) {
            // Resolved when playback is started, useful for cases when user is prompted before playing sound
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play
            this._audio.play().then(() => {
              this._timer = new Timer(null, resolve, duration + pause, this.src);
            });
          } else {
            // Otherwise resolve as soon as sound played + pause
            this._audio.addEventListener("ended", () => {
              if (pause) {
                this._timer = new Timer(null, resolve, pause, src);
              } else {
                resolve(src);
              }
            });

            this._audio.play();
          }
        }
      });
    });
  }

  public pause(): void {
    this._audio?.pause();
    this._timer?.pause();
  }

  public terminate(): void {
    if (this._audio) {
      this._audio.currentTime = 0;
      this._audio.pause();
    }

    this._timer?.terminate();
  }

  public resume(): void {
    this._audio?.play();
    this._timer?.resume();
  }

  public restart(): void {
    if (this._audio) {
      // Setting current time to zero should be before audio pausing due to bugs in chrome
      this._audio.currentTime = 0;
      this._audio.pause();
      this._audio.play();
      this._timer?.restart();
    }
  }
}
