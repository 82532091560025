// Will be in external files
export default {
  DEFAULT_ATTEMPT_NUMBER: 0,
  MAX_INCORRECT_ANSWERS_ATTEMPTS: 10000,

  // Store constants
  BLENDING_GUID: "deab7df7-3ba6-4f44-bf0c-88b90a46ce76",

  DATA_URL: "Lesson/GetInitialData",
  COMPLETE_LESSON_URL: "Progress/CompleteLesson",

  CORRECT_KEY_SELECTED_KEY: "correct_user_key_selected",

  EXERCISE_FILTER_KEY: "exercise_filter",

  AUTH_URL: "OldEstrellitaToken/AuthorizeStudent",
  AUTH_STORAGE_KEY: "auth_token",

  // Array with syllable grids per con
  CON_A_ARRAY: [
    "SilabasConA",
    "ma",
    "pa",
    "sa",
    "la",
    "ta",
    "da",
    "ra",
    "ca",
    "na",
    "fa",
    "ba",
    "ja",
    "ga",
    "cha",
    "ña",
    "va",
    "lla",
    "za",
    "ha",
    "ya"
  ],
  CON_E_ARRAY: [
    "SilabasConE",
    "me",
    "pe",
    "se",
    "le",
    "te",
    "de",
    "re",
    "ce",
    "ne",
    "fe",
    "be",
    "je",
    "ge",
    "che",
    "ñe",
    "ve",
    "lle",
    "ze",
    "he",
    "ye"
  ],
  CON_I_ARRAY: [
    "SilabasConI",
    "mi",
    "pi",
    "si",
    "li",
    "ti",
    "di",
    "ri",
    "ci",
    "ni",
    "fi",
    "bi",
    "ji",
    "gi",
    "chi",
    "ñi",
    "vi",
    "lli",
    "qui",
    "zi",
    "hi",
    "yi"
  ],
  CON_O_ARRAY: [
    "SilabasConO",
    "mo",
    "po",
    "so",
    "lo",
    "to",
    "do",
    "ro",
    "co",
    "no",
    "fo",
    "bo",
    "jo",
    "go",
    "cho",
    "ño",
    "vo",
    "llo",
    "zo",
    "ho",
    "yo"
  ],
  CON_U_ARRAY: [
    "SilabasConU",
    "mu",
    "pu",
    "su",
    "lu",
    "tu",
    "du",
    "ru",
    "cu",
    "nu",
    "fu",
    "bu",
    "ju",
    "gu",
    "chu",
    "ñu",
    "vu",
    "llu",
    "zu",
    "hu",
    "yu"
  ]
};
