export class SysHelper {
  /**
   * Random number in between
   * @param min included
   * @param max included
   */
  static randomBetween(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * Randomize array using Durstenfeld shuffle algorithm
   */
  static shuffleArray<T>(input: T[]): T[] {
    const output = input.slice();

    for (let i = output.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = output[i];
      output[i] = output[j];
      output[j] = temp;
    }

    return output;
  }

  /**
   * Returns a random element of the array (exclude element cannot be returned)
   * Max picking attempts = 999
   * Returns undefined if element wasn't picked
   * @param array Array of elements to lookup
   * @param exclude Array of elements which should be excluded / shouldn't be returned
   */
  static pickRandom<T>(array: T[], exclude?: T[]): T | undefined {
    const index = this.pickRandomIndex(array, exclude);
    if (index === -1) {
      return undefined;
    } else {
      return array[index];
    }
  }

  static pickRandomIndex<T>(array: T[], exclude?: T[]): number {
    const maxIndex = array.length - 1;
    let currentAttempts = 0;
    const maxAttempts = 999;

    // IE 11 doesn't support new Set(iterable)
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    const excludeSet = new Set();

    if (exclude) {
      for (let i = 0; i < exclude.length; i++) {
        excludeSet.add(exclude[i]);
      }
    }

    while (currentAttempts++ < maxAttempts) {
      const index = this.randomBetween(0, maxIndex);

      if (!excludeSet.has(array[index])) {
        return index;
      }
    }

    return -1;
  }

  /**
   * Returns an array without duplication values
   */
  static getUniqueArrayValues<T>(arrayWithDuplications: T[]): T[] {
    return arrayWithDuplications.filter((value, index, arr) => arr.indexOf(value) === index);
  }

  /**
   * Checks if the key is valid for given object, prevents from getting error below:
   * Element implicitly has an 'any' type because type '{ online: string; offline: string; busy: string; dnd: string; }' has no index signature.
   */
  static hasKey<O>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj;
  }
}
