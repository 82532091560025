import { LogLevelEnum } from "@/ts/system/logger/LogLevelEnum";

export abstract class BaseLogger {
  public abstract debug(message: any, caller?: string): void | Promise<void>;
  public abstract info(message: any, caller?: string): void | Promise<void>;
  public abstract warn(message: any, caller?: string): void | Promise<void>;
  public abstract error(message: any, caller?: string): void | Promise<void>;

  getStackTrace(level: LogLevelEnum): any {
    let stack;

    try {
      throw new Error(level);
    } catch (error) {
      return error;
    }
  }
}
