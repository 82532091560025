import { BaseLogger } from "./BaseLogger";
import { ConsoleLogger } from "./ConsoleLogger";
import { LogLevelEnum } from "./LogLevelEnum";

export class LoggerService {
  public static MinimumLogLevel: LogLevelEnum = LogLevelEnum.Debug;

  protected static _loggers: BaseLogger[] = [new ConsoleLogger()];
  public get loggers(): BaseLogger[] {
    return LoggerService._loggers;
  }

  public static register(logger: BaseLogger) {
    LoggerService._loggers.push(logger);
  }

  public static debug(message: any, caller?: string): void | Promise<void> {
    if (LoggerService.MinimumLogLevel === LogLevelEnum.Debug) {
      LoggerService._loggers.forEach(logger => logger.debug(message, caller));
    }
  }

  public static info(message: any, caller?: string): void | Promise<void> {
    if (
      LoggerService.MinimumLogLevel === LogLevelEnum.Info ||
      LoggerService.MinimumLogLevel === LogLevelEnum.Debug
    ) {
      LoggerService._loggers.forEach(logger => logger.info(message, caller));
    }
  }

  public static warn(message: any, caller?: string): void | Promise<void> {
    if (
      LoggerService.MinimumLogLevel === LogLevelEnum.Warning ||
      LoggerService.MinimumLogLevel === LogLevelEnum.Info ||
      LoggerService.MinimumLogLevel === LogLevelEnum.Debug
    ) {
      LoggerService._loggers.forEach(logger => logger.warn(message, caller));
    }
  }

  public static error(message: any, caller?: string): void | Promise<void> {
    LoggerService._loggers.forEach(logger => logger.error(message, caller));
  }
}
