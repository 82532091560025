//! NOTE: data and errors are moved to children/finalized APIs
// Otherwise, for example, if data has been changed inside of finalized API
// then 2way binding may catch data from this endpoint and it may lead to errors
// See lessons.blending for concrete example (data is heavily changed there before being passed to component)
import { ref, computed, Ref } from "@vue/composition-api";
import axios from "axios";
import { useJwtTokenProvider } from "@/ts/composition/useJwtTokenProvider";
import { IApiAdapter } from "@/ts/vue/IApiAdapter";

let activeRequestsCount: Ref;

export function useApiWithAuth(apiBaseUrl: string): IApiAdapter {
  const token = useJwtTokenProvider().getToken();
  return useApiInternal(apiBaseUrl, token ? token : undefined);
}

export function useApi(apiBaseUrl: string): IApiAdapter {
  return useApiInternal(apiBaseUrl);
}

function useApiInternal(apiBaseUrl: string, accessToken?: string): IApiAdapter {
  activeRequestsCount = activeRequestsCount || ref(0);

  const isLoading = computed(() => activeRequestsCount.value !== 0);

  function getApi() {
    const instance = axios.create({
      baseURL: apiBaseUrl,
      timeout: 60 * 60000, // 60 minutes
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });

    // Set Authorization header for each instance if needed
    if (accessToken) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    }

    return instance;
  }

  function get(path: string, query?: any, config?: any): Promise<any> {
    activeRequestsCount.value++;

    let queryString = "";

    if (query) {
      queryString =
        "?" +
        Object.entries(query)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
          .join("&");
    }

    return getApi()
      .get(path + queryString, config)
      .finally(() => {
        activeRequestsCount.value--;
      });
  }

  function post(path: string, payload?: any, config?: any): Promise<any> {
    activeRequestsCount.value++;

    return getApi()
      .post(path, payload, config)
      .finally(() => {
        activeRequestsCount.value--;
      });
  }

  return {
    isLoading,
    activeRequestsCount,

    get,
    post
  } as IApiAdapter;
}
