// TODO: compare with BaseCompositeExercise and polymorf;
import { Type } from "class-transformer";
import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";
import { ConcatenatorExercise } from "@/ts/domain/exercises/ConcatenatorExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { Delegate } from "@/ts/system/Delegate";

export type CompositeExerciseDelegate2<T extends BaseExercise> = (exercise: T, row: number, column: number) => Promise<any>;

// Provides base functionality for iterable (row/column) exercises such as concatenator
export abstract class BaseCompositeExercise2<T extends BaseExercise>
  extends BaseExercise {
  // Server props
  // https://github.com/typestack/class-transformer/blob/master/sample/sample4-generics/SuperCollection.ts
  // Generic decorators are not supported, see _readme.md
  @Type(() => ConcatenatorExercise)
  public children: T[][] = [];

  moveHorizontally: boolean = true;

  // Frontend props
  rowIndex: number = 0;
  columnIndex: number = 0;
  active: T[][] = [];

  get currentItem(): T | null {
    return this.active[this.rowIndex] ?
      this.active[this.rowIndex][this.columnIndex] :
      null;
  }

  // Delegates
  onItemStarted: Delegate<CompositeExerciseDelegate2<T>> = new Delegate<CompositeExerciseDelegate2<T>>();
  onItemFinished: Delegate<CompositeExerciseDelegate2<T>> = new Delegate<CompositeExerciseDelegate2<T>>();

  // Methods
  abstract start(filter?: LessonExerciseFilter): Promise<boolean>;
  abstract continue(): Promise<boolean>;
  abstract repeat(filter?: LessonExerciseFilter): Promise<boolean>;
  abstract stop(): Promise<void>;

  override async terminateExercise() {
    await super.terminateExercise();
    this.children.forEach(e => e.forEach(x => x.terminateExercise()));

    this.reset();
  }

  override reset(): void {
    super.reset();

    this.rowIndex = 0;
    this.columnIndex = 0;
    this.children.forEach(e => e.forEach(x => x.reset()));
  }

  filter(filter?: LessonExerciseFilter): void {
    this.active = !filter || !filter.keys || !filter.keys.length ?
      this.children :
      this.children.map(e => e.filter(x => filter.keys.some(key => key === x.text)));
  }

  // Starts exercise at row/number
  async startAtIndex(row: number, column: number): Promise<boolean> {
    this.rowIndex = row;
    this.columnIndex = column;

    const exercise = this.currentItem;

    if (exercise) {
      this.bindExerciseEvents(exercise, row, column);
      await exercise.start();
      return true;
    }

    // No next child exercise found
    return false;
  }

  // Starts exercise at given row/number and continues playing other exercises after the first one is finished
  protected async startFromIndexRecursively(row: number, column: number): Promise<boolean> {
    this.rowIndex = row;
    this.columnIndex = column;

    const result = await this.startAtIndex(row, column);

    if (result) {
      this.updateIndexes();
      return await this.startFromIndexRecursively(this.rowIndex, this.columnIndex);
    } else if (this.rowIndex < this.active.length) {
      // In case current row hasn't any syllable with columnIndex, but next row has;

      // This algorithm will cover use case with this type of structure:
      // "lla", "za",
      // "lle", "ze",
      // "lli", "qui", "zi",
      // or
      // "lla", "za",
      // "lle", "ze", "zi",
      // "lli", "qui",

      // It won't fix structure like this:
      // "lla", "za", "ha", "ya"
      // "lle", "ze",
      // "lli", "qui"

      this.rowIndex++;

      return await this.startFromIndexRecursively(this.rowIndex, this.columnIndex);
    }

    return false;
  }

  // TODO: refactor due to incoming content(data could be with different amount of syllables per row);
  protected updateIndexes(): void {
    if (this.moveHorizontally) {
      const nextRow = !this.active[this.rowIndex][this.columnIndex + 1];

      if (nextRow) {
        this.rowIndex++;
        this.columnIndex = 0;
      } else {
        this.columnIndex++;
      }
    } else {
      const nextColumn = !this.active[this.rowIndex + 1];

      if (nextColumn) {
        this.rowIndex = 0;
        this.columnIndex++;
      } else {
        this.rowIndex++;
      }
    }
  }

  protected bindExerciseEvents(exercise: BaseExercise, row: number, column: number) {
    exercise.onBeforeStarted.add(async () => {
      console.warn(this.constructor.name + " (" + this.text + ") onItemStarted (" + exercise.text + ").");
      await this.onItemStarted.execute(exercise, row, column);
    }, true);

    exercise.onFinished.add(async () => {
      console.warn(this.constructor.name + " (" + this.text + ") onItemFinished (" + exercise.text + ").");
      await this.onItemFinished.execute(exercise, row, column);
    }, true);
  }
}
