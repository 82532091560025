import { Type } from "class-transformer";
import { Lesson } from "@/ts/domain/Lesson";
import { GridLesson } from "@/ts/domain/GridLesson";
import { SelectorLesson } from "@/ts/domain/SelectorLesson";
import { SelectorSequenceLesson } from "@/ts/domain/SelectorSequenceLesson";

export class Segment {
  // Server props
  id: string = "";
  name: string = "";
  sequenceNumber: number = 0;
  description: string = "";
  totalLessons: number = 0;

  // TODO
  progress: any = {};

  @Type(() => Lesson, {
    discriminator: {
      property: "type",
      subTypes: [
        { value: GridLesson, name: "GridLesson" },
        { value: Lesson, name: "ConcatenatorSequenceLesson" },
        { value: SelectorSequenceLesson, name: "SelectorSequenceLesson" },
        { value: SelectorLesson, name: "SelectorLesson" }
      ]
    },
    keepDiscriminatorProperty: true
  })
  lessons: (Lesson | GridLesson | SelectorLesson | SelectorSequenceLesson)[] = [];
}
