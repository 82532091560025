import { plainToClass, classToClass } from "class-transformer";

import { Program } from "@/ts/domain/Program";
import { Module } from "@/ts/domain/Module";
import { Segment } from "@/ts/domain/Segment";
import { Lesson } from "@/ts/domain/Lesson";
import { GridExercise } from "@/ts/domain/exercises/GridExercise";
import { SelectorExercise } from "@/ts/domain/exercises/SelectorExercise";
import { ConcatenatorSequenceExercise } from "@/ts/domain/exercises/ConcatenatorSequenceExercise";

export class LessonsFactory {
  constructor(public program: string) {}

  async loadProgram(recursive: boolean = true): Promise<Program | void> {
    const raw = await this.loadJson(`${this.program}/definition`);

    if (raw && recursive) {
      raw.modules = await this.loadRawModules(raw.totalModules, recursive);
    }

    return this.resolve<Program>(Program, raw);
  }

  async loadModules(count: number, recursive: boolean = true): Promise<Module[]> {
    const raw = await this.loadRawModules(count, recursive);
    return this.resolveCollection<Module>(Module, raw);
  }

  async loadSegments(module: string, count: number, recursive: boolean = true): Promise<Segment[]> {
    const raw = await this.loadRawSegments(module, count, recursive);
    return this.resolveCollection<Segment>(Segment, raw);
  }

  async loadLessons(module: string, segment: string, count: number): Promise<Lesson[]> {
    const raw = await this.loadRawLessons(module, segment, count);
    return this.resolveCollection<Lesson>(Lesson, raw);
  }

  async loadModule(module: string, recursive: boolean = true): Promise<Module | void> {
    const raw = await this.loadRawModule(module, recursive);
    return this.resolve<Module>(Module, raw);
  }

  async loadSegment(
    module: string,
    segment: string,
    recursive: boolean = true
  ): Promise<Segment | void> {
    const raw = await this.loadRawSegment(module, segment, recursive);
    return this.resolve<Segment>(Segment, raw);
  }

  async loadLesson(module: string, segment: string, lesson: string): Promise<Lesson | void> {
    const raw = await this.loadRawLesson(module, segment, lesson);
    return this.resolve<Lesson>(Lesson, raw);
  }

  private async loadRawModules(count: number, recursive: boolean = true): Promise<any> {
    const result = [];

    for (let i = 0; i < count; i++) {
      const tmp = await this.loadRawModule(`module${i + 1}`, recursive);
      result.push(tmp);
    }

    return result.sort((a: any, b: any) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1));
  }

  private async loadRawModule(moduleName: string, recursive: boolean = true): Promise<any> {
    const module = await this.loadJson(`${this.program}/${moduleName}/definition`);

    if (module && recursive) {
      module.segments = await this.loadRawSegments(moduleName, module.totalSegments, true);
    }

    return module;
  }

  private async loadRawSegments(
    moduleName: string,
    count: number,
    recursive: boolean = true
  ): Promise<any> {
    const result = [];

    for (let i = 0; i < count; i++) {
      const tmp = await this.loadRawSegment(moduleName, `segment${i + 1}`, recursive);
      result.push(tmp);
    }

    return result.sort((a: any, b: any) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1));
  }

  private async loadRawSegment(
    moduleName: string,
    segmentName: string,
    recursive: boolean = true
  ): Promise<any> {
    const segment = await this.loadJson(`${this.program}/${moduleName}/${segmentName}/definition`);

    if (segment && recursive) {
      segment.lessons = await this.loadRawLessons(moduleName, segmentName, segment.totalLessons);
    }

    return segment;
  }

  private async loadRawLessons(
    moduleName: string,
    segmentName: string,
    count: number
  ): Promise<any> {
    const result = [];

    for (let i = 0; i < count; i++) {
      const tmp = await this.loadRawLesson(moduleName, segmentName, `lesson${i + 1}`);
      result.push(tmp);
    }

    return result.sort((a: any, b: any) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1));
  }

  private async loadRawLesson(
    moduleName: string,
    segmentName: string,
    lessonName: string
  ): Promise<any> {
    const lesson = await this.loadJson(
      `${this.program}/${moduleName}/${segmentName}/${lessonName}`
    );

    if (lesson && lesson.serverMimic && lesson.serverMimic.ref) {
      const lessonData = await this.loadJson(lesson.serverMimic.ref);

      if (lessonData) {
        lesson.exercises = lessonData.exercises;
      }
    }

    LessonDecorator.decorate(lesson);
    return lesson;
  }

  private async loadJson(path: string): Promise<any> {
    const result = await import("@/data/" + path + ".json");
    // The same JSON-files are used across different lessons
    // Need to create separate object instances for each lesson/data load
    // Doing it with classToClass method, it simply creates object's deepcopy
    return classToClass(result.default);
  }

  // We have to always resolve plain object to class during the final step
  // Otherwise, class decorators (like @Type) may not work as expected
  private resolve<T>(c: new () => T, obj: any): T {
    return plainToClass(c, obj);
  }
  private resolveCollection<T>(c: new () => T, obj: any[]): T[] {
    return plainToClass(c, obj);
  }
}

class LessonDecorator {
  public static decorate(lesson: Lesson) {
    switch (lesson.type) {
      case "GridLesson":
        LessonType1Decorator.decorate(lesson);
        break;
      case "SelectorSequenceLesson":
        LessonType2Decorator.decorate(lesson);
        break;
      case "SelectorLesson2":
      case "SelectorLesson3":
        LessonType4Decorator.decorate(lesson);
        break;
      case "ConcatenatorSequenceLesson":
        LessonType5Decorator.decorate(lesson);
        break;
    }
  }
}

class LessonType1Decorator {
  public static decorate(lesson: Lesson) {
    if (!lesson.serverMimic) {
      return;
    }

    const exercises = lesson.exercises;
    for (let i = 0; i < exercises.length; i++) {
      const grid = exercises[i] as GridExercise;
      const keys = lesson.serverMimic.keys as string[];
      const serverGridStructure = lesson.serverMimic.serverGridStructure as number[];
      const exerciseGridStructure = grid.exerciseGridStructure;

      grid.exerciseGridStructure = serverGridStructure
        ? serverGridStructure
        : exerciseGridStructure;

      if (keys && keys.length) {
        grid.children.forEach(children => {
          children.isActive = keys.some(key => key === children.text);
        });
      }
    }
  }
}

class LessonType2Decorator {
  public static decorate(lesson: Lesson) {
    if (!lesson.serverMimic) {
      return;
    }

    const keys = lesson.serverMimic.keys as string[];
    if (keys && keys.length) {
      lesson.exercises = lesson.exercises.filter(exercise =>
        keys.some(key => key === exercise.text)
      );
    }
  }
}

class LessonType4Decorator {
  public static decorate(lesson: Lesson) {
    if (!lesson.serverMimic) {
      return;
    }

    LessonType2Decorator.decorate(lesson);

    const childKeys = lesson.serverMimic.child as string[];
    const exercises = lesson.exercises;
    if (childKeys && childKeys.length) {
      for (let i = 0; i < exercises.length; i++) {
        const selector = exercises[i] as SelectorExercise;
        // @ts-ignore
        selector.children = selector.children.filter(opt =>
          childKeys.some(key => key === opt.text)
        );
      }
    }
  }
}

class LessonType5Decorator {
  public static decorate(lesson: Lesson) {
    if (!lesson.serverMimic) {
      return;
    }

    const exercises = lesson.exercises;

    for (let i = 0; i < exercises.length; i++) {
      const concatenator = exercises[i] as ConcatenatorSequenceExercise;
      const keys = lesson.serverMimic.keys as string[];

      if (keys && keys.length) {
        const rowsArray: any[] = [];

        concatenator.children.forEach(child => {
          rowsArray.push(child.filter(exercise => keys.some(key => key === exercise.text)));
        });

        concatenator.children = rowsArray;
      }
    }
  }
}
