import { BaseCompositeExercise } from "@/ts/domain/exercises/BaseCompositeExercise";
import { Type } from "class-transformer";
import { SyllableExercise } from "@/ts/domain/exercises/SyllableExercise";

/*
  Supposed to show letters/syllables at the left/right sides. <br />
  <b>Automatically</b> plays sounds of these letters/syllables and merges it into one syllable.
 */
export class ConcatenatorExercise extends BaseCompositeExercise<SyllableExercise> {
  // Server props
  @Type(() => SyllableExercise)
  public children: SyllableExercise[] = [];

  // Frontend props
  highlight: string = "";

  // Methods
  async start(): Promise<boolean> {
    this.active = this.children;
    await this.executeStart();
    await this.startFromIndexRecursively(0);
    await this.executeFinish();
    return false; // nothing to play
  }

  async continue(): Promise<boolean> {
    return await this.startFromIndexRecursively(++this.currentIndex);
  }

  //! adding new reset - Temp desicion while we did not fix clearing Lesson/Exercise state and Delegate subscriptions on finish
  override reset() {
    super.reset();

    this.highlight = "";
    this.isDisabled = true;
    this.currentIndex = 0;
  }

  async repeat(): Promise<boolean> {
    this.reset();
    return await this.start();
  }

  async stop(): Promise<void> {
    throw "Not implemented yet.";
  }
}
