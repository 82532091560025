<template>
  <div class="stars" :class="{ 'd-none': hideIfEmpty && value === 0 }">
    <div class="text">
      <div class="chars">
        <span class="char char1">H</span>
        <span class="char char2">a</span>
        <span class="char char3">s</span>
        <span class="char char4" />
        <span class="char char5">G</span>
        <span class="char char6">a</span>
        <span class="char char7">n</span>
        <span class="char char8">a</span>
        <span class="char char9">d</span>
        <span class="char char10">o</span>
      </div>
    </div>
    <img class="icon" src="~@/assets/little-star.png" alt="Little star" />
    <span class="star-count font-weight-bold text-h4">{{ value }}</span>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {},

  props: {
    value: {
      type: Number,
      default: 0
    },
    hideIfEmpty: {
      type: Boolean,
      default: true
    }
  },

  setup() {}
});
</script>

<style scoped lang="scss">
.stars {
  & .star-count {
    position: absolute;
    left: 0;
    width: 100%;
    top: 33%;
    color: #b32317;
    text-align: center;
    padding-left: 4%;
  }

  & .icon {
    width: 100%;
    max-width: 120px;
  }

  & .text {
    font-size: 16px;
    position: absolute;
    top: 45px;
    left: 5px;

    & .chars {
      transform: rotate(-100deg);
    }

    & .char {
      height: 55px;
      position: absolute;
      /*width: 15px;*/
      left: 0;
      top: 0;
      transform-origin: bottom center;
    }

    @for $i from 1 through 10 {
      & .char#{$i} {
        transform: rotate(($i * 17) + deg);
      }
    }
  }
}
</style>
