import { IPlayable } from "@/ts/sounds/IPlayable";
import { Sound } from "@/ts/sounds/Sound";

export class SoundPlayer implements IPlayable {
  public audio: Sound[] = [];

  private _isPlaying: boolean = false;
  public get isPlaying(): boolean {
    return this._isPlaying;
  }

  public get isPlayable(): boolean {
    return this.audio.length !== 0;
  }

  private _lastIndex: number = -1;

  constructor(audio: Sound | Sound[]) {
    this.audio = Array.isArray(audio) ? audio : [audio];
  }

  public play(index?: number): Promise<string> {
    if (!this.isPlayable) {
      return Promise.resolve("");
    }

    this._lastIndex = typeof index === "undefined" ? ++this._lastIndex : index;

    return this.playAtIndex(this._lastIndex).finally(() => {
      this._isPlaying = false;
    });
  }

  public playRecursively(): Promise<string> {
    if (!this.isPlayable) {
      return Promise.resolve("");
    }

    return this.playNextRecursively(0);
  }

  private playAtIndex(index: number): Promise<string> {
    const sound = this.audio[index];
    if (sound) {
      this._isPlaying = true;
      return sound.play();
    } else {
      this._isPlaying = false;
      return Promise.resolve("");
    }
  }

  private playNextRecursively(index: number): Promise<string> {
    return this.playAtIndex(index).then(() => {
      if (this._isPlaying) {
        return this.playNextRecursively(++index);
      } else {
        return Promise.resolve("");
      }
    });
  }

  async terminatePlayer() {
    await this.audio.forEach(async e => await e.terminate());
  }
}
