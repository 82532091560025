

























import { defineComponent, PropType } from "@vue/composition-api";
import { SyllableExercise } from "@/ts/domain/exercises/SyllableExercise";

export default defineComponent({
  components: {},

  props: {
    exercise: {
      type: Object as PropType<SyllableExercise>,
      required: true
    },
    isClickable: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    animationClass: {
      type: String,
      default: "bounce-out"
    }
  },

  setup(props, { emit }) {
    function play(): Promise<any> {
      return props.exercise.start();
    }

    function _onClick() {
      if (!props.isDisabled && props.isClickable) {
        emit("click");
      }
    }

    return {
      play,

      _onClick
    };
  }
});
