

















































import { defineComponent, ref } from "@vue/composition-api";
import LoginScreenWrap from "@/components/login/LoginScreenWrap.vue";

export default defineComponent({
  components: {
    LoginScreenWrap
  },

  props: {
    errors: Object
  },

  setup(props, { emit }) {
    // Fields
    const header = ref("Dar una buena acogida!");
    const subheader = ref(
      "Ingrese su nombre de clase, su nombre y haga clic en el botón de inicio de sesión para comenzar."
    );
    const loginFormRef = ref();
    const studentsName = ref("");
    const className = ref("");
    const isValid = ref(false);
    const validation = {
      classRequired: (value: string) => !!value || "Please, enter class name.",
      nameRequired: (value: string) => !!value || "Please, enter your name."
    };

    // Methods
    function login(): void {
      loginFormRef.value.validate();
      if (isValid.value) {
        emit("login", studentsName, className);
      }
    }

    return {
      header,
      subheader,
      loginFormRef,
      studentsName,
      className,
      validation,
      isValid,
      login
    };
  }
});
