import { Type } from "class-transformer";
import { Sound } from "@/ts/sounds/Sound";

export class LessonInstructions {
  @Type(() => Sound)
  public audio: Sound[];

  constructor(audio: Sound[] = []) {
    this.audio = audio;
  }
}
