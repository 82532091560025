





















import axios from "axios";

import { useRouter } from "@/ts/composition/vue-router";

import { useUserStore } from "@/store/useUserStore";
import { VueRouter } from "vue-router/types/router";
import ProtectedLayout from "@/views/layout/ProtectedLayout.vue";
import UnprotectedLayout from "@/views/layout/UnprotectedLayout.vue";
import TheProgress from "@/components/singletons/TheProgress.vue";
import TheBackground from "@/components/singletons/TheBackground.vue";
import TheBlendingBackground from "@/components/singletons/TheBlendingBackground.vue";
import TheLessonBackground from "@/components/singletons/TheLessonBackground.vue";
import TheFooter from "@/components/singletons/TheFooter.vue";

export default {
  name: "App",

  components: {
    TheProgress,
    ProtectedLayout,
    UnprotectedLayout,
    TheBackground,
    TheBlendingBackground,
    TheLessonBackground,
    TheFooter
  },

  setup() {
    const router = useRouter();
    const userStore = useUserStore();

    useApiInterceptor(userStore.logout, router);

    function logoutInternal(): void {
      userStore.logout().then(() => {});
      router.push("/login");
    }

    return {
      isLoading: userStore.isLoading,
      isAuthenticated: userStore.isAuthenticated,

      logoutInternal
    };
  }
};

function useApiInterceptor(logout: Function, router: VueRouter): void {
  // Application-level redirect
  axios.interceptors.response.use(undefined, function(err) {
    return new Promise<void>(function(resolve) {
      if (err.status === 401) {
        logout().then(function() {
          router.push("/login");
          resolve();
        });
      } else {
        resolve();
      }
    });
  });
}
