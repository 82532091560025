import { Type } from "class-transformer";
import { ConcatenatorExercise } from "@/ts/domain/exercises/ConcatenatorExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { BaseCompositeExercise2 } from "@/ts/domain/exercises/BaseCompositeExercise2";

/*
  Shows series of ConcatenatorExercise <br />
  <b>Automatically</b> plays one after another
 */
export class ConcatenatorSequenceExercise extends BaseCompositeExercise2<ConcatenatorExercise> {
  // Server props
  @Type(() => ConcatenatorExercise)
  public children: ConcatenatorExercise[][] = [];

  // Frontend props

  // Methods
  async start(filter?: LessonExerciseFilter): Promise<boolean> {
    this.filter(filter);
    await this.executeStart();
    await this.startFromIndexRecursively(0, 0);
    await this.executeFinish();
    return false;
  }

  async continue(): Promise<boolean> {
    this.updateIndexes();
    return await this.startFromIndexRecursively(this.rowIndex, this.columnIndex);
  }

  async repeat(filter?: LessonExerciseFilter): Promise<boolean> {
    this.reset();
    return await this.start(filter);
  }

  async stop(): Promise<void> {
    throw "Not implemented yet.";
  }
}
