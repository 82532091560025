import { BaseLogger } from "./BaseLogger";
import { LogMessageBuilder } from "./LogMessageBuilder";
import { LogLevelEnum } from "./LogLevelEnum";

export class ConsoleLogger extends BaseLogger {
  builder: LogMessageBuilder;

  constructor() {
    super();

    this.builder = new LogMessageBuilder();
  }

  debug(message: any, caller?: string): void {
    const callerLine = this.getCallerLine();
    const msg = this.builder.build(message, new Date(), LogLevelEnum.Debug, caller);

    console.info(msg);
    if (typeof message !== "string") {
      console.info(message);
    }
    console.info(callerLine);
  }

  info(message: any, caller?: string): void {
    const callerLine = this.getCallerLine() + "\n\n";
    const msg = this.builder.build(message, new Date(), LogLevelEnum.Info, caller);

    console.info(msg);
    if (typeof message !== "string") {
      console.info(message);
    }
    console.info(callerLine);
  }

  warn(message: any, caller?: string): void {
    const callerLine = this.getCallerLine() + "\n\n";
    const msg = this.builder.build(message, new Date(), LogLevelEnum.Warning, caller);

    console.warn(msg);
    if (typeof message !== "string") {
      console.warn(message);
    }

    console.warn(this.getStackTrace(LogLevelEnum.Warning));
    console.warn(callerLine);
  }

  error(message: any, caller?: string): void {
    const callerLine = this.getCallerLine() + "\n\n";
    const msg = this.builder.build(message, new Date(), LogLevelEnum.Error, caller);

    console.error(msg);
    if (typeof message !== "string") {
      console.error(message);
    }

    console.error(this.getStackTrace(LogLevelEnum.Error));
    console.error(callerLine);
  }

  private getCallerLine(): string {
    let result = new Error().stack?.split("\n")[6] ?? "";
    result = result.replace(" at ", "");
    return result.trim();
  }
}
