





















import { defineComponent, PropType } from "@vue/composition-api";
import Syllable from "@/components/exercises/Syllable.vue";
import { ConcatenatorExercise } from "@/ts/domain/exercises/ConcatenatorExercise";
import { SyllableExercise } from "@/ts/domain/exercises/SyllableExercise";
import { delay } from "@/ts/system/delay";
import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";

/**
 * Implements logic for ConcatenatorExercise.ts
 */
export default defineComponent({
  components: {
    Syllable
  },

  props: {
    exercise: {
      type: Object as PropType<ConcatenatorExercise>,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    props.exercise.onItemStarted.add(async (exercise: SyllableExercise) => {
      exercise.highlight = "success";
    });

    props.exercise.onItemFinished.add(async (exercise: SyllableExercise) => {
      exercise.highlight = "";
    });

    props.exercise.onBeforeFinished.add(async (exercise: BaseExercise) => {
      (exercise as ConcatenatorExercise).highlight = "success";
    });

    props.exercise.onFinished.add(async (exercise: BaseExercise) => {
      (exercise as ConcatenatorExercise).highlight = "";
      finishExercise();
    });

    props.exercise.onStarted.add(async () => {
      // let animations finish
      await delay(500);
    });

    function startExercise(): void {
      props.exercise.start();
    }

    function repeatExercise(): void {
      props.exercise.repeat();
    }

    function continueExercise(): void {
      props.exercise.continue();
    }

    function finishExercise(): void {
      emit("exercise-finished");
    }

    return {
      startExercise,
      continueExercise,
      repeatExercise
    };
  }
});
